import clsx from "clsx";

export default function IconSvg({ className, style, icon, color, onClick }) {
  return (
    <div
      onClick={onClick}
      className={clsx("relative", className)}
      style={{
        mask: `url(${icon}) no-repeat center`,
        backgroundColor: color,
        ...style,
      }}
    />
  );
}
