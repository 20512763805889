import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import iconCancel24 from "assets/icons/cancel24.svg";
import clsx from "clsx";

export default function ModalBase({
  open,
  handleClose,
  className,
  title,
  children,
}) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <div className="absolute w-full sm:w-auto outline-none top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border-none shadow-lg rounded-2xl">
          <div
            className={clsx(
              "bg-white rounded-2xl flex-col justify-start items-center inline-flex",
              className
            )}
          >
            <div className="self-stretch h-[58px] p-4 bg-[#f3f3f3] flex-col justify-start items-center gap-2 flex rounded-tl-2xl rounded-tr-2xl">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="grow shrink basis-0 h-[26px] justify-between items-center flex">
                  <div className="grow shrink basis-0 text-[#232323] text-lg font-bold leading-relaxed">
                    {title}
                  </div>
                </div>
                <div
                  className="w-6 h-6 flex justify-center items-center cursor-pointer"
                  onClick={handleClose}
                >
                  <img src={iconCancel24} alt="" />
                </div>
              </div>
            </div>

            {children}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
