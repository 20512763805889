import ModalBase from "components/Modal/ModalBase";
import { useServices } from "hooks/useServices";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { shareLink } from "services";

export function ModalShare({ id, typeShare, shareUrl, open, handleClose }) {
  const title = "Star Club";
  const services = useServices();

  const handleClickButton = () => {
    console.log("click", shareUrl);
    services(() =>
      shareLink({ object_id: id, type: typeShare, url: shareUrl })
    ).then(() => {});
  };
  return (
    <ModalBase
      title={"Chia sẻ"}
      open={open}
      handleClose={handleClose}
      className="w-full sm:w-[485px]"
    >
      <div className="w-full px-4 py-8 flex justify-center items-center gap-4 flex-wrap">
        <div className="Demo__some-network">
          <FacebookShareButton
            onClick={handleClickButton}
            url={shareUrl}
            className="Demo__some-network__share-button"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <div>
            <FacebookShareCount
              url={shareUrl}
              className="Demo__some-network__share-count"
            >
              {(count) => count}
            </FacebookShareCount>
          </div>
        </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            onClick={handleClickButton}
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <XIcon size={32} round />
          </TwitterShareButton>
        </div>

        <div className="Demo__some-network">
          <TelegramShareButton
            onClick={handleClickButton}
            url={shareUrl}
            title={title}
            className="Demo__some-network__share-button"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            onClick={handleClickButton}
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>

        <div className="Demo__some-network">
          <LinkedinShareButton
            onClick={handleClickButton}
            url={shareUrl}
            className="Demo__some-network__share-button"
          >
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div className="Demo__some-network">
          <RedditShareButton
            onClick={handleClickButton}
            url={shareUrl}
            title={title}
            windowWidth={660}
            windowHeight={460}
            className="Demo__some-network__share-button"
          >
            <RedditIcon size={32} round />
          </RedditShareButton>

          <div>
            <RedditShareCount
              url={shareUrl}
              className="Demo__some-network__share-count"
            />
          </div>
        </div>
        <div className="Demo__some-network">
          <EmailShareButton
            onClick={handleClickButton}
            url={shareUrl}
            subject={title}
            body="body"
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
      </div>
    </ModalBase>
  );
}

export default ModalShare;
