import React from "react";

export default function ActionModal({
  titleCancel = "Hủy",
  titleOK = "Đồng ý",
  onCancel,
  onOK,
  loading,
}) {
  return (
    <div className="self-stretch h-10 justify-end items-center gap-2 flex">
      <button
        onClick={onCancel}
        className="px-4 h-10 bg-[#eeeeee] rounded-3xl justify-start items-center gap-2 flex"
      >
        <div className="text-[#232323] text-sm font-normal leading-snug">
          {titleCancel}
        </div>
      </button>
      <button
        disabled={loading}
        onClick={onOK}
        className="px-4 h-10 bg-[#ff418c] rounded-3xl justify-start items-center gap-2 flex"
      >
        <div className="text-white text-sm font-normal leading-snug">
          {titleOK}
        </div>
      </button>
    </div>
  );
}
