import ModalBase from "./ModalBase";

export default function ModalSignIn({ open, handleClose }) {
  return (
    <ModalBase
      title={"Thông báo"}
      open={open}
      handleClose={handleClose}
      className="w-full sm:w-[485px]"
    >
      <div className="py-8 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch px-4 flex-col justify-start items-start gap-6 flex">
            <div className="self-stretch text-[#232323] text-sm font-normal leading-snug">
              Bạn cần đăng nhập để trải nghiệm dịch vụ
            </div>
            <div className="self-stretch h-10 justify-center items-center flex">
              <button
                onClick={handleClose}
                className="px-4 h-10 bg-[#ff418c] rounded-3xl justify-start items-center gap-2 flex"
              >
                <div className="text-white text-sm font-normal leading-snug">
                  Đóng
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  );
}
