import { useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNextSong,
  handlePrevSong,
  shuffleSongs,
} from "../redux/slice/playerSlice";
import { TYPE_PLAY_IN_LIST } from "../constants";
import { isFirstElement, isLastElement } from "utils";

export default function useControlPlayer() {
  const {
    infoAudio,
    isPlaying,
    loading,
    isShowRecommend,
    loop,
    playListRecommend,
    playListInAlbum,
    playingIn,
  } = useSelector((state) => state.player);
  const progressBarRef = useRef();
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const checkIsPlayingASong = useCallback(
    (song) => {
      return isPlaying && infoAudio && song && infoAudio?.id === song?.id;
    },
    [infoAudio, isPlaying]
  );

  const checkIsActiveASong = useCallback(
    (song) => {
      return infoAudio && song && infoAudio?.id === song?.id;
    },
    [infoAudio]
  );

  const onPlayAudio = useCallback(() => {
    if (infoAudio) {
      const btn = document.getElementById("button-play");
      btn?.click();
    }
  }, [infoAudio]);

  const onResetProgressAudio = useCallback(() => {
    progressBarRef.current.value = 0;
    progressBarRef.current.style.setProperty("--range-progress", `0%`);
  }, []);

  const onSetProgressAudio = useCallback((currentTime, duration) => {
    progressBarRef.current.value = currentTime;
    progressBarRef.current.style.setProperty(
      "--range-progress",
      `${(currentTime / duration) * 100}%`
    );
  }, []);

  const onPreviousAudio = useCallback(() => {
    onResetProgressAudio();
    dispatch(handlePrevSong());
  }, [onResetProgressAudio, dispatch]);

  const onNextAudio = useCallback(() => {
    onResetProgressAudio();
    dispatch(handleNextSong());
  }, [dispatch, onResetProgressAudio]);

  const onShuffleAudio = useCallback(() => {
    dispatch(shuffleSongs());
  }, [dispatch]);

  const isDisableNext = useMemo(() => {
    let isDisable = false;
    const checkRecommend = () => {
      let isDis = false;
      if (playListRecommend?.length) {
        const indexSongInRecommend = playListRecommend.findIndex(
          (i) => i.id === infoAudio?.id
        );
        if (isLastElement(playListRecommend, indexSongInRecommend)) {
          if (!loop) {
            isDis = true;
          }
        }
      } else {
        isDis = true;
      }
      return isDis;
    };
    if (!userInfo) {
      isDisable = true;
    }
    if (playingIn === TYPE_PLAY_IN_LIST.album) {
      if (playListInAlbum?.length) {
        const indexSongInAlbum = playListInAlbum.findIndex(
          (i) => i.id === infoAudio?.id
        );
        if (isLastElement(playListInAlbum, indexSongInAlbum)) {
          if (!loop) {
            isDisable = true;
          }
        }
      } else {
        isDisable = checkRecommend();
      }
    } else {
      isDisable = checkRecommend();
    }

    return isDisable;
  }, [infoAudio?.id, loop, playListInAlbum, playListRecommend, playingIn, userInfo]);

  const isDisablePrev = useMemo(() => {
    let isDisable = false;
    if (!userInfo) {
      isDisable = true;
    }
    if (playingIn === TYPE_PLAY_IN_LIST.album) {
      const indexSongInAlbum = playListInAlbum.findIndex(
        (i) => i.id === infoAudio?.id
      );
      if (isFirstElement(indexSongInAlbum)) {
        isDisable = true;
      }
    } else if (playingIn === TYPE_PLAY_IN_LIST.recommend) {
      const indexSongInRecommend = playListRecommend.findIndex(
        (i) => i.id === infoAudio?.id
      );
      if (isFirstElement(indexSongInRecommend)) {
        isDisable = true;
      }
    } else if (playingIn === TYPE_PLAY_IN_LIST.song) {
      isDisable = true;
    }

    return isDisable;
  }, [infoAudio?.id, playListInAlbum, playListRecommend, playingIn, userInfo]);

  return {
    checkIsPlayingASong,
    checkIsActiveASong,
    progressBarRef,
    onPlayAudio,
    onResetProgressAudio,
    onSetProgressAudio,
    onPreviousAudio,
    onNextAudio,
    onShuffleAudio,
    isDisableNext,
    isDisablePrev,
  };
}
