import { Collapse } from "@mui/material";
import heart from "assets/icons/heart16.svg";
import iconSend20 from "assets/icons/send20.svg";
import clsx from "clsx";
import CustomEmoji from "components/Emoji";
import IconSvg from "components/IconSvg";
import { useCallback, useRef, useState } from "react";

export default function CommentItem({
  comment,
  sendComment,
  level,
  handleLike,
  isLiked,
  userInfo,
  idLevel1,
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 100);
  }, []);

  const handleSend = useCallback(() => {
    sendComment(
      {
        comment_id: level === 2 ? idLevel1 : comment?.id,
        content: inputValue,
      },
      () => {
        setInputValue("");
      }
    );
  }, [comment?.id, idLevel1, inputValue, level, sendComment]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleSend();
      }
    },
    [handleSend]
  );

  return (
    <div>
      <div
        className={clsx(
          "w-full overflow-hidden justify-start items-start gap-2 flex",
          {
            "pt-2": level > 1,
          }
        )}
      >
        <img
          className="w-7 h-7 rounded-full object-cover border border-[#ff418c] cursor-pointer"
          src={comment?.user?.avatar}
          alt=""
        />
        <div className="flex-1 flex-col justify-start items-start flex">
          <div className="w-full justify-start items-center gap-1 inline-flex">
            <div className="text-[#767676] text-xs font-normal leading-[18px] cursor-pointer">
              {comment?.user?.fullname}
            </div>
            <div className="w-1 h-1 bg-[#8c8c8c] rounded-full" />
            <div className="text-[#767676] text-xs font-normal leading-[18px]">
              {comment?.create_time}
              {/* phút trước */}
            </div>
          </div>
          <div className="w-full text-[#232323] text-sm font-normal leading-snug">
            {comment?.content}
          </div>
          <div className="pt-2 justify-start items-center gap-3 inline-flex">
            <div className="justify-start items-center gap-1 flex">
              <div className="w-5 h-5 relative">
                <IconSvg
                  onClick={() => handleLike(comment?.id)}
                  icon={heart}
                  className={clsx("w-[16px] h-[16px] cursor-pointer", {
                    like: isLiked,
                    unlike: !isLiked,
                  })}
                  color={isLiked ? "#FF418C" : "#545454"}
                />
              </div>
              <div className="text-[#232323] text-xs font-normal leading-[18px]">
                {comment?.total_likes}
              </div>
            </div>
            <div
              onClick={handleToggle}
              className="text-[#232323] text-xs font-normal leading-[18px] cursor-pointer"
            >
              Phản hồi
            </div>
          </div>
          {level === 1 && (
            <Collapse in={open} className="w-full">
              <CommentInput
                handleKeyDown={handleKeyDown}
                handleSend={handleSend}
                inputValue={inputValue}
                setInputValue={setInputValue}
                userInfo={userInfo}
                inputRef={inputRef}
              />
            </Collapse>
          )}

          {comment?.childs && comment?.childs?.length > 0 && (
            <div>
              {comment?.childs?.map((reply) => (
                <CommentItem
                  key={`$comment-${reply.id}`}
                  comment={reply}
                  sendComment={sendComment}
                  level={2}
                  handleLike={handleLike}
                  isLiked={reply?.userLike?.status}
                  idLevel1={idLevel1}
                  userInfo={userInfo}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {level === 2 && (
        <Collapse in={open} className="w-full">
          <CommentInput
            handleKeyDown={handleKeyDown}
            handleSend={handleSend}
            inputValue={inputValue}
            setInputValue={setInputValue}
            userInfo={userInfo}
            inputRef={inputRef}
          />
        </Collapse>
      )}
    </div>
  );
}

const CommentInput = ({
  inputValue,
  setInputValue,
  handleKeyDown,
  handleSend,
  userInfo,
  inputRef,
}) => {
  return (
    <div className="w-full h-12 pt-2 justify-start items-start gap-2 inline-flex">
      <img
        className="w-7 h-7 rounded-full object-cover border border-[#ff418c]"
        src={userInfo?.avatar}
        alt=""
      />
      <div className="grow shrink basis-0 h-10 px-4 py-1 bg-[#ffecf4] rounded-[100px] justify-center items-center gap-4 flex">
        <input
          ref={inputRef}
          placeholder="Bình luận..."
          type="text"
          className="w-[150px] grow shrink basis-0 border-none outline-none px-1 bg-transparent placeholder:text-sm placeholder:text-232323 placeholder:text-opacity-40"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <div className="justify-start items-center gap-2 flex">
          <CustomEmoji setInputValue={setInputValue} />

          <div onClick={handleSend} className="w-5 h-5 relative cursor-pointer">
            <IconSvg
              icon={iconSend20}
              className="w-[20px] h-[20px] cursor-pointer"
              color={"#FF418C"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
