import iconExpand from "assets/icons/expand.svg";
import iconShink from "assets/icons/shink.svg";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { closeMenuLeft, toggleMenuLeft } from "../../redux/slice/menuLeftSlice";

import hambuger from "assets/icons/hambuger.svg";
import { useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import MenuItem from "./MenuItem";
import { MENU_LEFT } from "./constants";
import { Link, useNavigate } from "react-router-dom";
import { appRoutes } from "../../constants";
import { setStatusModalAuth } from "../../redux/slice/authSlice";

export default function MenuLeft() {
  const open = useSelector((state) => state.menuLeft.open);
  const dispatch = useDispatch();
  let timeout = useRef();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    const handleResize = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        const width = window.innerWidth;
        if (width <= 768) {
          if (timeout) {
            clearTimeout(timeout);
          }
          dispatch(closeMenuLeft());
        }
      }, 300);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dispatch]);

  const onClickMenuItem = (item) => {
    if (item.url) {
      if ([appRoutes.home, appRoutes.videos].includes(item.url)) {
        navigate(item.url);
      } else {
        if (userInfo) {
          navigate(item.url);
        } else {
          dispatch(setStatusModalAuth(true));
        }
      }
      const width = window.innerWidth;
      if (width <= 768) {
        dispatch(closeMenuLeft());
      }
    }
  };

  return (
    <div className="bg-white z-[101] md:z-20 absolute left-0 top-0 h-full md:relative">
      <PerfectScrollbar
        className={clsx(
          "h-full bg-white border-r border-[#eeeeee] flex-col justify-start items-start flex flex-shrink-0 transition-all duration-300",
          {
            "w-[260px]": open,
            "w-0 md:w-[70px]": !open,
          }
        )}
      >
        <div className="self-stretch justify-start items-center gap-2.5 inline-flex">
          <div className="grow shrink basis-0 py-1 flex-col justify-start items-start inline-flex">
            <div className="self-stretch flex-col justify-start items-start flex">
              <div className="self-stretch flex-col justify-start items-start flex">
                <div className="self-stretch pl-[22px] pr-4 py-5 justify-start items-center gap-3 inline-flex">
                  {open && (
                    <Link to={appRoutes.home} className="grow shrink basis-0">
                      <div className="h-[38px] justify-start items-center gap-3 flex">
                        <div className="text-[#232323] font-racing-sans-one text-[26px] font-normal font-['Racing Sans One'] leading-[38px]">
                          Star*Club
                        </div>
                      </div>
                    </Link>
                  )}
                  <div
                    className="w-6 h-6 relative flex justify-center items-center cursor-pointer"
                    onClick={() => dispatch(toggleMenuLeft())}
                  >
                    <img className="md:hidden" src={hambuger} alt="" />
                    <img
                      className="hidden md:block"
                      src={open ? iconShink : iconExpand}
                      alt=""
                    />
                  </div>
                </div>

                <div className="self-stretch py-1 flex-col justify-start items-start flex">
                  <div className="self-stretch px-3 py-2 flex-col justify-start items-start gap-1.5 flex">
                    {MENU_LEFT.map((item, index) => {
                      if (item.type === "menu") {
                        return (
                          <MenuItem
                            key={`menu-${index}`}
                            icon={item.icon}
                            title={item.title}
                            open={open}
                            regex={item.regex}
                            onClick={() => onClickMenuItem(item)}
                            isActive={item.regex?.test(
                              window.location.pathname
                            )}
                          />
                        );
                      }
                      if (item.type === "divider") {
                        return (
                          <div
                            key={`menu-divider-${index}`}
                            className="self-stretch h-[46px] pt-3 flex-col justify-start items-start flex"
                          >
                            <div className="self-stretch h-[34px] px-3 py-1.5 flex-col justify-center items-start gap-2 flex">
                              <div className="text-[#232323] text-sm font-semibold leading-tight flex justify-center">
                                {open ? (
                                  item.title
                                ) : (
                                  <div className="w-5 h-[1px] border-t border-t-[#CACACA]" />
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      }
                      return "";
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {open ? (
            <div className="text-center w-full text-[#8c8c8c] text-sm absolute bottom-[6px]">
              Version: {process.env.REACT_APP_VERSION}
            </div>
          ) : null}
        </div>
      </PerfectScrollbar>
    </div>
  );
}
