import { Menu } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setLoggedIn, setUserInfo } from "../../redux/slice/authSlice";
import { INFO_LOGIN, removeItemStorage } from "utils/storage";
import iconUserInfo from "assets/icons/user-info20.svg";
import iconLogout from "assets/icons/logout20.svg";

export default function AccountMenu({ userInfo }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="relative select-none">
      <div
        className="w-10 h-10 cursor-pointer justify-center items-center gap-2.5 flex rounded-3xl  bg-[#eeeeee]"
        onClick={handleClick}
      >
        <img
          className="w-10 h-10 left-0 top-0 absolute object-cover rounded-full"
          src={userInfo?.avatar}
          alt=""
        />
      </div>
      <div className="w-3 h-3 left-[28px] top-[28px] absolute bg-[#f8f7fa] rounded-[64px]">
        <div className="w-2 h-2 left-[2px] top-[2px] absolute">
          <div className="w-2 h-2 left-0 top-0 absolute bg-[#28c76f] rounded-full" />
        </div>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            sx: {
              padding: 0,
              borderRadius: "12px",
              boxShadow: "0px 4px 8px 0px #00000029",
              "& .MuiList-root": {
                padding: 0,
              },
            },
          },
        }}
      >
        <div className="w-[240px] p-3 flex-col justify-start items-center inline-flex select-none">
          <div className="self-stretch flex-col justify-start items-start flex">
            <div className="self-stretch flex-col justify-start items-center flex">
              <div className="self-stretch pb-4 justify-start items-center gap-2 inline-flex">
                <img
                  className="w-[52px] h-[52px] object-cover rounded-full"
                  src={userInfo?.avatar}
                  alt=""
                />
                <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                  <div className="self-stretch text-black text-base font-bold  leading-snug">
                    {userInfo?.fullname}
                  </div>
                  <div className="justify-center items-center gap-2.5 inline-flex">
                    <div className="px-1 py-1 bg-[#767676] rounded justify-center items-center gap-1 flex">
                      <div className="text-white text-[10px] font-normal  leading-none">
                        {userInfo?.premiums?.[0]?.serviceName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {userInfo?.premiums?.[0]?.is_pro === 0 ? (
                <div className="self-stretch pb-4 justify-center items-center gap-2.5 inline-flex">
                  <div className="grow shrink basis-0 h-[30px] px-4 py-1.5 bg-[#ffecf4] hover:shadow-sm duration-500 transition-all cursor-pointer select-none rounded-[40px] justify-center items-center gap-2.5 flex">
                    <div className="text-[#ff418c] text-xs font-normal  leading-[18px]">
                      Nâng cấp tài khoản
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="border-t border-t-[#EEEEEE] h-[1px] w-full"></div>
              <ItemMenu
                icon={iconUserInfo}
                title={"Cập nhật thông tin"}
                onClick={handleClose}
              />
              <ItemMenu
                icon={iconLogout}
                title={"Đăng xuất"}
                onClick={() => {
                  dispatch(setUserInfo(null));
                  dispatch(setLoggedIn(false));
                  removeItemStorage(INFO_LOGIN);
                  handleClose();
                }}
              />
            </div>
          </div>
        </div>
      </Menu>
    </div>
  );
}

const ItemMenu = ({ icon, title, onClick }) => {
  return (
    <div
      className="self-stretch p-3 rounded-md cursor-pointer transition-all duration-200 justify-start items-center gap-2.5 inline-flex hover:bg-[#cacaca]/50"
      onClick={onClick}
    >
      <div className="w-5 h-5 relative">
        <img src={icon} alt="" />
      </div>
      <div className="text-[#545454] text-sm font-normal  leading-snug">
        {title}
      </div>
    </div>
  );
};
