import { Menu } from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import { Fragment, useState } from "react";
import iconSmiley20 from "assets/icons/smiley20.svg";

const CustomEmoji = ({ setInputValue, iconEmoji }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEmojiClick = (emojiObject) => {
    if (emojiObject && emojiObject.emoji) {
      setInputValue((prev) => prev + emojiObject.emoji);
      handleClose();
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <div onClick={handleClick} className="w-5 h-5 relative cursor-pointer">
        <img src={iconEmoji ?? iconSmiley20} alt="emoji" />
      </div>
      {open && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "12px",
                boxShadow: "0px 4px 8px 0px #00000029",
              },
            },
          }}
        >
          <EmojiPicker onEmojiClick={onEmojiClick} lazyLoadEmojis={true} />
        </Menu>
      )}
    </Fragment>
  );
};

export default CustomEmoji;
