import iconComment18 from "assets/icons/comment18.svg";
import iconLyric18 from "assets/icons/lyric18.svg";
import iconPlayList from "assets/icons/menu/play-list.svg";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { IoMdVolumeHigh, IoMdVolumeLow, IoMdVolumeOff } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  setVolume,
  toggleComment,
  toggleLyric,
} from "../../redux/slice/playerSlice";
import { togglePlayList } from "../../redux/slice/playerSlice";
import { TYPE_COMMENT } from "../../constants";

const Volume = ({ audioRef, songDetail }) => {
  const [muteVolume, setMuteVolume] = useState(false);
  const dispatch = useDispatch();
  const { openPlayList, volume, openComment } = useSelector(
    (state) => state.player
  );
  
  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100;
      audioRef.current.muted = muteVolume;
    }
  }, [volume, audioRef, muteVolume]);

  return (
    <div className="hidden lg:flex items-center gap-3">
      <div className="flex items-center">
        <button
          onClick={() => setMuteVolume((prev) => !prev)}
          className="mr-0 text-[20px]"
        >
          {muteVolume || volume < 5 ? (
            <IoMdVolumeOff />
          ) : volume < 40 ? (
            <IoMdVolumeLow />
          ) : (
            <IoMdVolumeHigh />
          )}
        </button>
        <input
          type="range"
          min={0}
          max={100}
          value={volume}
          onChange={(e) => dispatch(setVolume(e.target.value))}
          className="input-volume ml-[4px] h-[8px]"
          style={{
            background: `linear-gradient(to right, #FF418C ${volume}%, #ccc ${volume}%)`,
          }}
        />
      </div>
      {songDetail?.lyrics && (
        <button
          onClick={() => dispatch(toggleLyric())}
          className="w-10 h-10 px-2.5 hover:bg-[#eeeeee] rounded-[6px] justify-center items-center flex cursor-pointer"
        >
          <div className="w-[18px] h-[18px] relative">
            <img src={iconLyric18} alt="" />
          </div>
        </button>
      )}
      <button
        onClick={() => dispatch(toggleComment(TYPE_COMMENT.audio))}
        className={clsx(
          "w-10 h-10 px-2.5 justify-center items-center flex cursor-pointer rounded-[6px]",
          {
            "bg-[#FFECF4]": openComment,
            "text-[#4C4C4C] hover:bg-[#eeeeee]": !openComment,
          }
        )}
      >
        <div className="w-[18px] h-[18px] relative">
          <img src={iconComment18} alt="" />
        </div>
      </button>
      <button
        onClick={() => dispatch(togglePlayList())}
        className={clsx(
          "w-10 h-10 px-2.5 justify-center items-center flex cursor-pointer rounded-[6px]",
          {
            "bg-[#FFECF4] text-[#FF418C] active": openPlayList,
            "text-[#4C4C4C] hover:bg-[#cacaca]/50": !openPlayList,
          }
        )}
      >
        <div
          className={clsx("icon-playlist-svg", {
            active: openPlayList,
          })}
          style={{
            mask: `url(${iconPlayList}) no-repeat center`,
          }}
        ></div>
      </button>
    </div>
  );
};

export default Volume;
