import { useSnackbar } from "notistack";
import { useCallback } from "react";

export const useServices = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useCallback(
    async (api, showError) => {
      try {
        const response = await api();
        if (response) {
          return response;
        }
        return null;
      } catch (err) {
        console.log(err);
        // if (err?.response?.status === 498) {
        //   dispatch(setStatusModalAuth(true));
        //   dispatch(setUserInfo(null));
        //   dispatch(setLoggedIn(false));
        //   removeItemStorage(INFO_LOGIN);
        // }
        const msg = err?.response?.data?.message ?? err.message;
        if (showError) {
          enqueueSnackbar(msg || "Lỗi không xác định", {
            key: "error",
            variant: "error",
            onClick: () => closeSnackbar("error"),
          });
        }
        return err;
      }
    },
    [closeSnackbar, enqueueSnackbar]
  );
};
