import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import ActionModal from "components/ActionModal";
import ErrorMessage from "components/Form/ErrorMessage";
import ModalBase from "components/Modal/ModalBase";
import { useSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { createPlayList, updatePlayList } from "services/playlist";
import { checkResponse } from "utils";
import * as yup from "yup";

const schema = yup
  .object({
    title: yup.string().required("Không được để trống"),
  })
  .required();

const optionsType = [
  {
    label: "Audio",
    value: "2",
  },
  {
    label: "Video",
    value: "1",
  },
];

export default function ModalAdd({ open, handleClose, data, loadData }) {
  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useForm({
    mode: "all",
    defaultValues: {
      id: data?.id ?? null,
      type: data?.type ?? "2",
      title: data?.title ?? "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (value) => {
    if (value?.id) {
      updatePlayList(value).then((res) => {
        if (checkResponse(res)) {
          enqueueSnackbar("Chỉnh sửa playlist thành công", {
            variant: "success",
          });
          loadData();
          handleClose();
        }
      });
      return;
    }

    createPlayList(value).then((res) => {
      if (checkResponse(res)) {
        enqueueSnackbar("Tạo playlist thành công", {
          variant: "success",
        });
        loadData(res.data);
        handleClose();
      }
    });
  };

  return (
    <ModalBase
      title={data?.id ? "Chỉnh sửa Playlist" : "Tạo Playlist"}
      open={open}
      handleClose={handleClose}
      className="w-[329px]"
    >
      <div className="self-stretch py-8 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch px-4 flex-col justify-start items-start gap-6 flex">
            <div className="flex items-center w-full gap-2 text-[#232323] text-sm font-normal leading-snug">
              <div className="flex items-center">
                <Controller
                  control={control}
                  name={"type"}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <div className="w-full">
                        <select
                          className="w-16 select-none border-none outline-none"
                          {...field}
                        >
                          {optionsType.map((item) => (
                            <option
                              key={item.value}
                              className=""
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  }}
                />{" "}
              </div>
              <div className="flex-1">
                <Controller
                  control={control}
                  name={"title"}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <div className="w-full">
                        <TextField
                          {...field}
                          placeholder="Nhập tên PlayList"
                          variant="standard"
                          sx={{ width: "100%" }}
                          error={!!error?.message}
                        />
                        <ErrorMessage message={error?.message} />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
            <ActionModal
              titleOK="Đồng ý"
              onCancel={handleClose}
              onOK={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </ModalBase>
  );
}
