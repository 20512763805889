import { yupResolver } from "@hookform/resolvers/yup";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import iconCancel24 from "assets/icons/cancel24.svg";
import iconLeft18 from "assets/icons/caret-left18.svg";
import LoadingButton from "components/Button/LoadingButton";
import PasswordInputField from "components/Form/PasswordInputField";
import TextInputField from "components/Form/TextField";
import { ERROR_MESSAGE } from "constants";
import { useServices } from "hooks/useServices";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { forgetPasswordApi, getOTP } from "services";
import { checkResponse, showNotiError, showNotiSuccess } from "utils";
import * as yup from "yup";

const STEPS = {
  step1: "1",
  step2: "2",
};

const schema = yup.object().shape({
  step: yup.string(),
  username: yup.string().required("Không được để trống"),
  password: yup
    .string()
    .nullable()
    .when("step", {
      is: (value) => value === STEPS.step2,
      then: (schema) => schema.required(ERROR_MESSAGE.required),
      otherwise: (schema) => schema,
    }),
  confirmPassword: yup
    .string()
    .nullable()
    .when("step", {
      is: (value) => value === STEPS.step2,
      then: (schema) =>
        schema
          .oneOf([yup.ref("password"), null], "Mật khẩu không khớp")
          .required("Bạn phải xác nhận mật khẩu"),
      otherwise: (schema) => schema,
    }),
  otp: yup
    .string()
    .nullable()
    .when("step", {
      is: (value) => value === STEPS.step2,
      then: (schema) => schema.required(ERROR_MESSAGE.required),
      otherwise: (schema) => schema,
    }),
});

export default function ForgetPasswordModal({
  open,
  handleClose,
  setIsLoginOpen,
}) {
  const [loading, setLoading] = useState(false);
  const services = useServices();
  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "all",
    defaultValues: {
      step: STEPS.step1,
      username: "",
      password: null,
      confirmPassword: null,
      otp: "",
    },
    resolver: yupResolver(schema),
  });

  const step = watch("step");

  const handleSendOTP = useCallback((phone) => {
    return new Promise((resolve) => {
      setLoading(true);
      getOTP({
        username: phone,
        prefix: "signup",
      })
        .then((res) => {
          if (checkResponse(res)) {
            // const timeLeft = res.data.data.otp_countdown;
            // setSeconds(Number(timeLeft));
            resolve(res);
          } else {
            showNotiError(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    if (step === STEPS.step1) {
      handleSendOTP(data.username).then((res) => {
        if (checkResponse(res)) {
          setValue("step", STEPS.step2);
        }
      });
    } else if (step === STEPS.step2) {
      console.log(data);
      services(() =>
        forgetPasswordApi({
          phone: data.username,
          otp: data.otp,
          password: data.password,
        })
      )
        .then((res) => {
          if (checkResponse(res)) {
            showNotiSuccess(
              "Thay đổi mật khẩu thành công. Mời bạn đăng nhập lại"
            );
            handleClose();
          } else {
            showNotiError(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <div className="absolute w-full sm:w-[485px] outline-none top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border-none shadow-lg rounded-2xl">
          <div className="w-full sm:w-[485px] bg-white rounded-2xl flex-col justify-start items-center inline-flex">
            <div className="self-stretch h-[58px] p-4 bg-[#f3f3f3] flex-col justify-start items-center gap-2 flex rounded-tl-2xl rounded-tr-2xl">
              <div className="self-stretch justify-between items-center inline-flex">
                <div
                  className="w-6 h-6 flex justify-center items-center cursor-pointer"
                  onClick={() => {
                    switch (step) {
                      case STEPS.step1:
                        handleClose();
                        setIsLoginOpen(true);
                        break;
                      case STEPS.step2:
                        setValue("step", STEPS.step1);
                        setValue("password", null);
                        setValue("confirmPassword", null);
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <img src={iconLeft18} alt="" />
                </div>
                <div className="grow shrink basis-0 h-[26px] justify-between items-center flex">
                  <div className="grow shrink basis-0 text-[#232323] text-lg font-bold leading-relaxed">
                    Quên mật khẩu
                  </div>
                </div>
                <div
                  className="w-6 h-6 flex justify-center items-center cursor-pointer"
                  onClick={handleClose}
                >
                  <img src={iconCancel24} alt="" />
                </div>
              </div>
            </div>
            <div className="self-stretch px-4 py-8 flex-col justify-start items-start gap-8 flex">
              {step === STEPS.step1 ? (
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch text-[#767676] text-sm font-normal leading-snug">
                    Vui lòng nhập email hoặc số điện thoai để tìm lại mật khẩu
                  </div>
                  <div className="self-stretch flex-col justify-start items-end gap-4 flex">
                    <TextInputField
                      placeholder={"Emai hoặc số điện thoại"}
                      name="username"
                      control={control}
                    />
                  </div>
                  <div className="self-stretch select-none flex-col justify-start items-start gap-4 flex">
                    <LoadingButton
                      loading={loading}
                      disabled={loading}
                      onClick={handleSubmit(onSubmit)}
                      className="self-stretch h-[46px] bg-[#ff418c] cursor-pointer select-none rounded-lg flex-col justify-center items-center gap-2 flex"
                    >
                      Gửi
                    </LoadingButton>
                  </div>
                </div>
              ) : null}
              {step === STEPS.step2 ? (
                <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                  <div className="self-stretch text-[#767676] text-sm font-normal leading-snug">
                    Vui lòng nhập mật khẩu mới của bạn
                  </div>
                  <div className="self-stretch flex-col justify-start items-end gap-4 flex">
                    <PasswordInputField
                      placeholder={"Mật khẩu mới"}
                      name="password"
                      control={control}
                    />
                    <PasswordInputField
                      placeholder={"Xác nhận mật khẩu mới"}
                      name="confirmPassword"
                      control={control}
                    />
                    <TextInputField
                      placeholder={"Nhập mã OTP"}
                      name="otp"
                      control={control}
                    />
                  </div>
                  <div className="self-stretch select-none flex-col justify-start items-start gap-4 flex">
                    <LoadingButton
                      loading={loading}
                      disabled={loading}
                      onClick={handleSubmit(onSubmit)}
                      className="self-stretch h-[46px] bg-[#ff418c] cursor-pointer select-none rounded-lg flex-col justify-center items-center gap-2 flex"
                    >
                      Gửi
                    </LoadingButton>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div id="recaptcha-container"></div>
        </div>
      </Fade>
    </Modal>
  );
}
