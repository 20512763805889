import clsx from "clsx";

const MenuItem = ({ icon, title, open, onClick, isActive }) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        "cursor-pointer select-none transition-all self-stretch group px-3 py-2 rounded-md justify-start items-center inline-flex",
        {
          "bg-[#ff418c]": isActive,
          "hover:bg-[#cacaca]/50": !isActive,
          "gap-2": open,
        }
      )}
    >
      
      <div
        className={clsx("w-[22px] h-[22px] relative icon-menu-svg", {
          active: isActive,
          "group-hover:bg-[#4c4c4c]": !isActive,
        })}
        style={{
          mask: `url(${icon}) no-repeat center`,
          // "-webkit-mask": `url(${icon}) no-repeat center`,
        }}
      />
      <div
        className={clsx("h-[22px] justify-center items-center gap-2.5 flex", {
          "w-auto grow shrink basis-0": open,
          "w-0 overflow-hidden hidden": !open,
        })}
      >
        <div
          className={clsx(
            "grow shrink basis-0  text-sm font-medium leading-snug",
            {
              "text-white": isActive,
              "text-[#8c8c8c] group-hover:text-[#4c4c4c]": !isActive,
            }
          )}
        >
          {title}
        </div>
      </div>
    </div>
  );
};

export default MenuItem;
