import iconPause from "assets/icons/pause.svg";
import iconPlay from "assets/icons/play.svg";
import iconRepeat18 from "assets/icons/repeat18.svg";
import iconShuffle18 from "assets/icons/shuffle18.svg";
import iconBack18 from "assets/icons/skip-back18.svg";
import iconForward18 from "assets/icons/skip-forward18.svg";
import IconSvg from "components/IconSvg";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { formatTime } from "utils";
import {
  setPlaybackRate,
  setTimeProgress,
  toggleLoop,
  togglePlayAudio,
} from "../../redux/slice/playerSlice";
import ButtonControl from "../ButtonControl";

const Controls = ({
  audioRef,
  progressBarRef,
  onPreviousAudio,
  onNextAudio,
  duration,
  isPlaying,
  error,
  loading,
  timeProgress,
  userInfo,
  loop,
  shuffle,
  infoAudio,
  onShuffleAudio,
  onSetProgressAudio,
  isDisableNext,
  isDisablePrev,
  playbackRate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const timeLeftWidth = useRef(0);
  const timeRightWidth = useRef(0);
  const [space, setSpace] = useState({ left: 0, right: 0 });
  let timeout = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        let timeLeftW = timeLeftWidth.current?.clientWidth + 10 || 0;
        let timeRightW = timeLeftWidth.current?.clientWidth + 10 || 0;
        setSpace({
          left: timeLeftW,
          right: timeRightW,
        });
      }, 300);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePlayPause = useCallback(() => {
    if (!error) {
      dispatch(togglePlayAudio());
    } else {
      enqueueSnackbar("Lỗi tải bài hát", {
        variant: "error",
      });
    }
  }, [dispatch, error, enqueueSnackbar]);

  const handleProgressChange = useCallback(() => {
    audioRef.current.currentTime = progressBarRef.current.value;
    onSetProgressAudio(audioRef.current.currentTime, duration);
    dispatch(setTimeProgress(Number(progressBarRef.current.value)));
  }, [audioRef, progressBarRef, onSetProgressAudio, duration, dispatch]);

  const handleLoop = () => {
    dispatch(toggleLoop());
    if (loop === 2) {
      audioRef.current.loop = true;
    } else {
      audioRef.current.loop = false;
    }
  };

  const handleSpeedChange = (e) => {
    const newRate = parseFloat(e.target.value);
    dispatch(setPlaybackRate(newRate));
    audioRef.current.playbackRate = newRate;
  };

  return (
    <div className="lg:w-[350px] 2xl:w-auto flex flex-col 2xl:flex-row justify-center items-center gap-2 2xl:gap-4">
      <div className="flex justify-center pl-2 sm:pl-10 2xl:pl-0">
        <div className="flex items-center gap-[2px]">
          <ButtonControl
            disabled={loading || !userInfo}
            onClick={onShuffleAudio}
            classNameButton="hidden lg:flex hover:bg-[#eaeaea]"
          >
            <IconSvg
              icon={iconShuffle18}
              className="w-[18px] h-[18px] cursor-inherit"
              color="#232323"
            />
            <span className="text-[10px]">{shuffle ? "on" : ""}</span>
          </ButtonControl>
          <ButtonControl
            disabled={isDisablePrev}
            onClick={onPreviousAudio}
            classNameButton="hover:bg-[#eaeaea]"
          >
            <IconSvg
              icon={iconBack18}
              className="w-[18px] h-[18px] cursor-inherit"
              color="#232323"
            />
          </ButtonControl>
          <button
            disabled={loading}
            onClick={togglePlayPause}
            className="w-10 h-10 mx-3 outline-none border-none"
            id="button-play"
          >
            {loading ? (
              <div className="w-10 h-10 flex justify-center items-center overflow-hidden">
                <RotatingLines
                  strokeColor={"grey"}
                  strokeWidth={"5"}
                  animationDuration={"0.75"}
                  width={30}
                  visible={true}
                />
              </div>
            ) : (
              <img src={isPlaying ? iconPause : iconPlay} alt="" />
            )}
          </button>
          <ButtonControl
            disabled={isDisableNext}
            onClick={onNextAudio}
            classNameButton="hover:bg-[#eaeaea]"
          >
            <IconSvg
              icon={iconForward18}
              className="w-[18px] h-[18px] cursor-inherit"
              color="#232323"
            />
          </ButtonControl>

          <ButtonControl
            disabled={loading || !userInfo}
            onClick={handleLoop}
            classNameButton="hidden lg:flex hover:bg-[#eaeaea]"
          >
            <IconSvg
              icon={iconRepeat18}
              className="w-[18px] h-[18px] cursor-inherit"
              color="#8C8C8C"
            />
            <span className="text-[10px]">
              {loop === 0 ? "" : loop === 1 ? "on" : loop === 2 ? "one" : ""}
            </span>
          </ButtonControl>
        </div>
      </div>

      <div
        style={{
          paddingLeft: `${space.left}px`,
          paddingRight: `${space.right}px`,
        }}
        className="hidden lg:flex justify-center items-center gap-[10px] w-full"
      >
        <div className="lg:w-[182px] xl:w-[286px] flex items-center relative">
          <span
            ref={timeLeftWidth}
            className="text-[12px] font-medium absolute right-[calc(100%+10px)]"
          >
            {formatTime(timeProgress)}
          </span>
          <input
            className="input-progress"
            disabled={!userInfo}
            type="range"
            ref={progressBarRef}
            defaultValue="0"
            onChange={handleProgressChange}
          />
          <div
            ref={timeRightWidth}
            className="absolute left-[calc(100%+10px)] flex items-center gap-3"
          >
            <span className="text-[12px] font-medium">
              {formatTime(duration)}
            </span>
            <select
              className="select-speed w-10 select-none border-none outline-none text-[12px]"
              id="speed"
              value={playbackRate}
              onChange={handleSpeedChange}
            >
              <option className="" value="0.5">
                0.5x
              </option>
              <option value="1">1.0x</option>
              <option value="1.5">1.5x</option>
              <option value="2">2.0x</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Controls;
