import React from "react";
import { RotatingLines } from "react-loader-spinner";

export default function LoadingButton({
  loading,
  children,
  disabled,
  onClick,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className="self-stretch h-[46px] bg-[#ff418c] cursor-pointer select-none rounded-lg flex-col justify-center items-center gap-2 flex"
    >
      {loading ? (
        <RotatingLines
          strokeColor={"white"}
          strokeWidth={"5"}
          animationDuration={"0.75"}
          width={25}
          visible={true}
        />
      ) : (
        <div className="self-stretch px-6 py-3 justify-center items-center gap-2 inline-flex">
          <div className="text-center text-white text-sm font-medium leading-snug">
            {children}
          </div>
        </div>
      )}
    </button>
  );
}
