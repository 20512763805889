import { useCallback, useState } from "react";

export default function useActionPlayer() {
  const [isOpenModalAddPlaylist, setOpenModalAddPlaylist] = useState(false);
  const [isOpenModalShare, setOpenModalShare] = useState(false);

  const onOpenModalShare = useCallback(() => {
    setOpenModalShare(true);
  }, []);

  const onCloseModalShare = useCallback(() => {
    setOpenModalShare(false);
  }, []);

  const onOpenModalAddPlaylist = useCallback(() => {
    setOpenModalAddPlaylist(true);
  }, []);

  const onCloseModalAddPlaylist = useCallback(() => {
    setOpenModalAddPlaylist(false);
  }, []);

  return {
    isOpenModalShare,
    onOpenModalShare,
    onCloseModalShare,
    isOpenModalAddPlaylist,
    onOpenModalAddPlaylist,
    onCloseModalAddPlaylist,
  };
}
