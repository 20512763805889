import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserDetail } from "services";
import { checkResponse } from "utils";
import { getItemStorage, INFO_LOGIN, removeItemStorage } from "utils/storage";

// Define the async thunk
export const fetchUserDetail = createAsyncThunk(
  "auth/getUserDetail",
  async (body) => {
    const response = await getUserDetail(body);
    if (checkResponse(response)) {
      return response?.data.data;
    } else {
      throw new Error("Failed to getUserDetail");
    }
  }
);

const initialState = {
  userInfo: null,
  loading: false,
  loggedIn: !!getItemStorage(INFO_LOGIN) || false,
  openModal: false,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState: initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setStatusModalAuth: (state, action) => {
      state.openModal = action.payload;
    },
    setLoggedIn(state, action) {
      state.loggedIn = action.payload;
    },
    resetAuth: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        if (action.payload) {
          state.loggedIn = true;
        } else {
          state.loggedIn = false;
        }
      })
      .addCase(fetchUserDetail.rejected, (state, action) => {
        state.loading = false;
        removeItemStorage(INFO_LOGIN);
      });
  },
});

export const { setUserInfo, setStatusModalAuth, setLoggedIn, resetAuth } =
  authSlice.actions;
export default authSlice.reducer;
