import iconCancel24 from "assets/icons/cancel24.svg";
import iconComment48 from "assets/icons/comment48.svg";
import iconSend20 from "assets/icons/send20.svg";
import iconSmiley24 from "assets/icons/smiley24.svg";
import clsx from "clsx";
import CustomEmoji from "components/Emoji";
import { useServices } from "hooks/useServices";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { createComments, getComments, likeComments } from "services/comment";
import { checkResponse } from "utils";
import { TYPE_COMMENT } from "../../constants";
import { setInfoVideo, toggleComment } from "../../redux/slice/playerSlice";
import CommentTree from "./CommentTree";

export default function CommentDrawer() {
  const { infoAudio, infoVideo, openComment, typeComment } = useSelector(
    (state) => state.player
  );
  const dispatch = useDispatch();
  const services = useServices();

  const [comments, setComments] = useState();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();
  const id = useMemo(() => {
    switch (
      typeComment //1:video, 2:audio
    ) {
      case TYPE_COMMENT.video:
        return infoVideo?.id;
      case TYPE_COMMENT.audio:
        return infoAudio?.id;
      default:
        return null;
    }
  }, [infoAudio, infoVideo, typeComment]);

  const loadComments = useCallback(() => {
    if (id && typeComment) {
      services(() =>
        getComments({
          object_id: id,
          object_type: typeComment,
        })
      ).then((res) => {
        if (checkResponse(res)) {
          setComments(res.data.data);
        }
      });
    }
  }, [id, services, typeComment]);

  useEffect(() => {
    loadComments();
    setTimeout(() => {
      if (openComment) {
        inputRef?.current?.focus();
      }
    }, 100);
  }, [loadComments, openComment]);

  const handleLike = useCallback(
    (id) => {
      services(() =>
        likeComments({
          id: id,
        })
      ).then((res) => {
        if (checkResponse(res)) {
          loadComments();
        }
      });
    },
    [loadComments, services]
  );

  const sendComment = useCallback(
    ({ comment_id, content }, callback) => {
      if (content) {
        const body = {
          content,
          comment_id,
          object_id: id,
          object_type: typeComment,
        };
        services(() => createComments(body)).then((res) => {
          console.log(res.data);
          loadComments();
          callback && callback();
        });
      }
    },
    [id, services, loadComments, typeComment]
  );

  const handleSend = useCallback(() => {
    sendComment({ comment_id: null, content: inputValue }, () => {
      setInputValue("");
    });
  }, [inputValue, sendComment]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        handleSend();
      }
    },
    [handleSend]
  );

  return (
    <div
      className={clsx(
        "h-full border-l border-[#eeeeee] bg-white z-[100] absolute right-0 top-0"
      )}
    >
      <PerfectScrollbar
        options={{
          suppressScrollX: true,
          useBothWheelAxes: false,
        }}
        className={clsx("transition-all duration-300 overflow-x-hidden", {
          "basis-[368px] w-[368px] flex-shrink-0 h-full overflow-y-auto":
            openComment,
          "basis-[0px] w-0 overflow-hidden": !openComment,
        })}
      >
        <div className="h-full pl-6 pr-5 pt-6 bg-white border-l border-[#eeeeee] flex-col justify-start items-start gap-4 inline-flex">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 flex">
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 flex">
              <div className="h-6 self-stretch flex-col justify-start items-center gap-2 flex">
                <div className="self-stretch justify-between items-center inline-flex">
                  <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2 flex">
                    <div className="grow shrink basis-0 text-[#232323] text-base font-bold leading-snug">
                      Bình luận
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      dispatch(toggleComment(null));
                      dispatch(setInfoVideo(null));
                    }}
                    className="w-10 h-10 flex justify-center items-center hover:bg-[#eeeeee] rounded-full relative cursor-pointer"
                  >
                    <img src={iconCancel24} alt="" />
                  </div>
                </div>
              </div>
              {comments?.length > 0 ? (
                <CommentTree
                  handleLike={handleLike}
                  comments={comments}
                  sendComment={sendComment}
                />
              ) : (
                <EmptyComment />
              )}
              <div className="w-80 pb-4 justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-11 px-4 bg-[#ffecf4] rounded-[100px] justify-center items-center gap-4 flex">
                  <input
                    ref={inputRef}
                    placeholder="Bình luận..."
                    type="text"
                    className="grow shrink basis-0 border-none outline-none px-1 bg-transparent placeholder:text-sm placeholder:text-232323 placeholder:text-opacity-40"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <CustomEmoji
                    iconEmoji={iconSmiley24}
                    setInputValue={setInputValue}
                  />
                </div>
                <div
                  onClick={handleSend}
                  className="w-11 h-11 bg-[#ff418c] rounded-3xl justify-center items-center gap-2.5 flex"
                >
                  <div className="w-5 h-5 relative cursor-pointer">
                    <img src={iconSend20} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}

const EmptyComment = () => {
  return (
    <div className="w-80 grow shrink basis-0 pb-2 flex-col justify-start items-center gap-3 flex">
      <div className="self-stretch grow shrink basis-0 px-4 py-14 flex-col justify-center items-center gap-3 flex">
        <div className="w-12 h-12 relative opacity-40">
          <img src={iconComment48} alt="" />
        </div>
        <div className="w-60 opacity-40 text-center text-[#3c3c3c] text-xs font-normal leading-[18px]">
          Hãy trở thành người bình luận đầu tiên
        </div>
      </div>
    </div>
  );
};
