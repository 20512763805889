import { createSlice } from "@reduxjs/toolkit";

const commonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    openModalDownloadApp: false,
  },
  reducers: {
    openModalDownloadApp: (state) => {
      state.openModalDownloadApp = true;
    },
    closeModalDownloadApp: (state) => {
      state.openModalDownloadApp = false;
    },
  },
});

export const { openModalDownloadApp, closeModalDownloadApp } =
  commonSlice.actions;
export default commonSlice.reducer;
