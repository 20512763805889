import React from "react";
import iconPlayDisc from "assets/icons/play-disc.svg";
import iconMusic70 from "assets/icons/music70.svg";
import black from "assets/icons/music-disc-back-black.svg";
import clsx from "clsx";

export default function DiscThumbnail({ isPlaying, thumb, classNameDisk }) {
  return (
    <div className={clsx("w-[441px] h-[274px] relative", classNameDisk)}>
      <div
        className={clsx(
          "w-[251.12px] h-[251.51px] rounded-full overflow-hidden left-[189.87px] top-[10.22px] absolute",
          {
            "animate-spin-slow": isPlaying,
          }
        )}
      >
        <div className="relative w-full h-full">
          <div
            className="w-10 h-10 p-4 z-[1] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full"
            style={{
              backgroundImage: `url(${thumb})`,
              backgroundPosition: "center",
            }}
          >
            <div className="w-6 h-6 z-[2] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white"></div>
          </div>
        </div>
        <img
          className="w-[251.12px] h-[251.51px] left-0 top-0 absolute"
          src={thumb}
          alt=""
        />
        <div className="w-[251.12px] h-[251.51px] left-0 top-0 absolute">
          <div className="w-[251.12px] h-[251.51px] left-0 top-0 absolute bg-[#14171e] rounded-full" />

          <img className="" src={black} alt="" />
          <div className="w-[150.11px] h-[150.34px] left-[50.51px] top-[50.58px] absolute">
            <div className="w-[150.11px] h-[150.34px] left-0 top-0 absolute bg-[#eeeeee] rounded-full">
              <img
                className="h-full w-full object-cover bg-cover bg-no-repeat rounded-full"
                src={thumb}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-[24.33px] h-[166.28px] left-[190.61px] top-[6.76px] absolute bg-gradient-to-r from-[#232323] to-black blur-[5.41px]" />
      <div className="w-[314.42px] relative h-[274px] rounded-xl overflow-hidden">
        {/* <img
          className="w-full h-full left-0 top-0 absolute object-cover"
          src={thumb}
          alt=""
        /> */}
        <svg width="0" height="0">
          <defs>
            <clipPath id="myClipPath">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8148 0C4.84196 0 0 4.84196 0 10.8148V263.185C0 269.158 4.84195 274 10.8148 274H303.602C309.575 274 314.417 269.158 314.417 263.185L314.417 157.448C303.141 157.448 294 148.276 294 137C294 125.724 303.141 116.552 314.417 116.552L314.417 10.8148C314.417 4.84196 309.575 0 303.602 0H10.8148Z"
              />
            </clipPath>
          </defs>
        </svg>
        <div
          className="my-clipped-div"
          style={{
            backgroundImage: `url(${thumb})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        />
        {!thumb ? (
          <img
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
            src={iconMusic70}
            alt=""
          />
        ) : null}
      </div>

      <div className="w-[104.12px] h-[28.43px] left-[265.42px] top-[34.76px] absolute">
        <img src={iconPlayDisc} alt="" />
      </div>
    </div>
  );
}
