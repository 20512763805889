import download from "assets/icons/download-app-white.svg";
import ModalBase from "./ModalBase";

export default function ModalNotiDowloadApp({ open, handleClose }) {
  return (
    <ModalBase
      title={"Thông báo"}
      open={open}
      handleClose={handleClose}
      className="w-[329px]"
    >
      {/* <div className="self-stretch h-[172px] py-8 flex-col justify-start items-start gap-8 flex">
    <div className="self-stretch h-[108px] flex-col justify-start items-start gap-3 flex">
      <div className="self-stretch h-[108px] px-4 flex-col justify-start items-start gap-6 flex">
        <div className="self-stretch text-[#232323] text-sm font-normal font-['Inter'] leading-snug">Bạn vui lòng tải ứng dụng để sử dụng tính năng này</div>
        <div className="self-stretch justify-end items-center gap-2 inline-flex">
          <div className="px-4 bg-[#eeeeee] rounded-3xl justify-start items-center gap-2 flex">
            <div className="text-[#232323] text-sm font-normal font-['Inter'] leading-snug">Hủy</div>
          </div>
          <div className="px-4 bg-[#ff418c] rounded-3xl justify-start items-center gap-2 flex">
            <div className="w-5 h-5 relative"></div>
            <div className="text-white text-sm font-normal font-['Inter'] leading-snug">Tải ứng dụng</div>
          </div>
        </div>
      </div>
    </div>
  </div> */}

      <div className="self-stretch py-8 flex-col justify-start items-start gap-8 inline-flex">
        <div className="self-stretch flex-col justify-start items-start gap-3 flex">
          <div className="self-stretch px-4 flex-col justify-start items-start gap-6 flex">
            <div className="text-[#232323] text-sm font-normal font-['Inter'] leading-snug">
              Bạn vui lòng tải ứng dụng để sử dụng tính năng này
            </div>

            <div className="self-stretch justify-end items-center gap-2 inline-flex ">
              <div
                className="h-10 px-4 bg-[#eeeeee] rounded-3xl justify-start items-center gap-2 inline-flex cursor-pointer"
                onClick={handleClose}
              >
                <div className="text-[#232323] text-sm font-normal font-['Inter'] leading-snug">
                  Hủy
                </div>
              </div>

              <a
                className="h-10 px-4 bg-[#ff418c] rounded-3xl justify-start items-center gap-2 inline-flex cursor-pointer"
                href="http://starclub.vn/about/download/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="w-5 h-5 relative">
                  <img src={download} alt="download" />
                </div>
                <div className="text-white text-sm font-normal font-['Inter'] leading-snug">
                  Tải ứng dụng
                </div>
              </a>

              {/* <a
                href="http://starclub.vn/about/download/"
                target="_blank"
                rel="noreferrer"
                className="hidden lg:block"
              >
                <div className="h-10 pl-4 pr-6 bg-[#ffecf4] hover:shadow-sm duration-500 rounded-3xl justify-start items-center gap-2 inline-flex">
                  <div className="w-6 h-6 relative">
                    <img src={download} alt="" />
                  </div>
                  <div className="text-[#ff418c] text-sm font-normal leading-snug">
                    Tải ứng dụng
                  </div>
                </div>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </ModalBase>
  );
}
