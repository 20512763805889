import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getItemStorage, INFO_LOGIN } from "utils/storage";
import { setStatusModalAuth } from "../redux/slice/authSlice";
import {
  setSingleSong,
  setSongInAlbum,
  setSongInRecommend,
} from "../redux/slice/playerSlice";

export default function useModalAuth() {
  const { userInfo, loggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const setASong = useCallback(
    (info) => {
      if (!userInfo) {
        dispatch(setStatusModalAuth(true));
      } else {
        dispatch(setSingleSong(info));
      }
    },
    [userInfo, dispatch]
  );

  const setASongInAlbum = useCallback(
    (info) => {
      if (!userInfo) {
        dispatch(setStatusModalAuth(true));
      } else {
        dispatch(setSongInAlbum(info));
      }
    },
    [userInfo, dispatch]
  );

  const setASongInRecommend = useCallback(
    (info) => {
      if (!userInfo) {
        dispatch(setStatusModalAuth(true));
      } else {
        dispatch(setSongInRecommend(info));
      }
    },
    [userInfo, dispatch]
  );

  const checkAuthToST = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (!loggedIn) {
        dispatch(setStatusModalAuth(true));
        reject(0);
      } else {
        resolve(1);
      }
    });
  }, [loggedIn, dispatch]);

  const checkIsAuth = useCallback(() => {
    const infoLogin = getItemStorage(INFO_LOGIN);
    return !!infoLogin;
  }, []);

  return {
    setASong,
    setASongInAlbum,
    setASongInRecommend,
    checkIsAuth,
    checkAuthToST,
  };
}
