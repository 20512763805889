export const INFO_LOGIN = "INFO_LOGIN";
export const SEARCH_KEY = "SEARCH_KEY";
export const UUID = "UUID";

export function saveItemStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

export function getItemStorage(key) {
  const storedData = localStorage.getItem(key);
  if (storedData) {
    return JSON.parse(storedData);
  }
  return null;
}

export function removeItemStorage(key) {
  localStorage.removeItem(key);
}

// search
export const saveSearchKeyword = (keyword) => {
  const words = getItemStorage(SEARCH_KEY) || [];
  if (!words.includes(keyword)) {
    words.push(keyword);
  }
  saveItemStorage(SEARCH_KEY, words);
};

export const removeSearchKeyword = (keyword) => {
  let words = getItemStorage(SEARCH_KEY) || [];
  words = words.filter((item) => item !== keyword);
  saveItemStorage(SEARCH_KEY, words);
};

export const hasSearchKeyword = () => {
  let words = getItemStorage(SEARCH_KEY) || [];
  return !!words.length;
};
