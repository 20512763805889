import apiClient from "./apiClient";

export const getPlaylists = (body) => {
  return apiClient.post("/playlists/list", body);
};

export const getPlaylistsDetail = (body) => {
  return apiClient.post("/playlists/detail", body);
};

export const createPlayList = (body) => {
  return apiClient.post("/playlists/create", body);
};
export const updatePlayList = (body) => {
  return apiClient.post("/playlists/update", body);
};

export const addAudioPlayList = (body) => {
  return apiClient.post("/playlists/add-audio-to-playlist", body); // audio_id, playlist_id
};

export const deleteAudioPlayList = (body) => {
  return apiClient.post("/playlists/delete", body); // playlist_id
};

