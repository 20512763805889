import React, { Suspense, lazy } from "react";
import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import { appRoutes } from "../constants/index";

const ArtistAlbum = lazy(() => import("pages/ArtistAlbum"));
const ArtistShort = lazy(() => import("pages/ArtistShort"));
const ArtistTopSong = lazy(() => import("pages/ArtistTopSong"));
const ArtistVideo = lazy(() => import("pages/ArtistVideo"));
const EventTicket = lazy(() => import("pages/EventTicket"));
const Favorites = lazy(() => import("pages/Favorites"));
const HistoryView = lazy(() => import("pages/HistoryView"));
const PlayList = lazy(() => import("pages/PlayList"));
const PlayListAudioDetail = lazy(() => import("pages/PlayListAudioDetail"));
const PlayListVideoDetail = lazy(() => import("pages/PlayListVideoDetail"));
const AudioPlayerSong = lazy(() => import("pages/AudioPlayerSong"));
const Search = lazy(() => import("pages/Search"));
const Shorts = lazy(() => import("pages/Shorts"));
const Artist = lazy(() => import("pages/Artist"));
const AudioPlayerAlbum = lazy(() => import("pages/AudioPlayerAlbum"));
const Home = lazy(() => import("pages/Home"));
const Video = lazy(() => import("pages/Video"));
const VideoDetail = lazy(() => import("pages/VideoDetail"));

const routers = [
  {
    name: "Home",
    exact: true,
    path: appRoutes.home,
    element: Home,
  },
  {
    name: "Video",
    exact: true,
    path: appRoutes.videos,
    element: Video,
  },
  {
    name: "Shorts",
    exact: true,
    path: appRoutes.shorts,
    element: Shorts,
  },
  {
    name: "ShortsDetail",
    exact: true,
    path: appRoutes.shortsDetail(),
    element: Shorts,
  },
  {
    name: "VideoDetail",
    exact: true,
    path: appRoutes.videoDetail(),
    element: VideoDetail,
  },
  {
    name: "AlbumPlayer",
    exact: true,
    path: appRoutes.playerAlbum(),
    element: () => <AudioPlayerAlbum />,
  },
  {
    name: "PlaylistPlayer",
    exact: true,
    path: appRoutes.playerPlaylist(),
    element: () => <AudioPlayerAlbum />,
  },
  {
    name: "SongPlayer",
    exact: true,
    path: appRoutes.playerSong(),
    element: () => <AudioPlayerSong />,
  },
  {
    name: "Artist",
    exact: true,
    path: appRoutes.artist(),
    element: Artist,
  },
  {
    name: "artist-video",
    exact: true,
    path: appRoutes.artistVideo(),
    element: ArtistVideo,
  },
  {
    name: "artist-short",
    exact: true,
    path: appRoutes.artistShort(),
    element: ArtistShort,
  },
  {
    name: "artist-album",
    exact: true,
    path: appRoutes.artistAlbum(),
    element: ArtistAlbum,
  },
  {
    name: "Vé sự kiện",
    exact: true,
    path: appRoutes.eventTicket,
    element: EventTicket,
  },
  {
    name: "Top song",
    exact: true,
    path: appRoutes.artistTopSong(),
    element: ArtistTopSong,
  },
  {
    name: "Yêu thích",
    exact: true,
    path: appRoutes.favorites,
    element: Favorites,
  },
  {
    name: "Vừa xem",
    exact: true,
    path: appRoutes.historyView,
    element: HistoryView,
  },
  {
    name: "Playlist",
    exact: true,
    path: appRoutes.playlist,
    element: PlayList,
  },
  {
    name: "Playlist-audio",
    exact: true,
    path: appRoutes.playlistAudioDetail(),
    element: PlayListAudioDetail,
  },
  {
    name: "Playlist-video",
    exact: true,
    path: appRoutes.playlistVideoDetail(),
    element: PlayListVideoDetail,
  },

  {
    name: "Search",
    exact: true,
    path: appRoutes.search,
    element: Search,
  },
];

export const Router = memo(() => {
  return (
    <Routes>
      {routers.map((item) => {
        const { element: Element, path } = item;
        let element = (
          <Suspense fallback={<div>Loading...</div>}>
            <Element />
          </Suspense>
        );

        // if (Layout) {
        //   element = <Layout>{element}</Layout>;
        // }

        return (
          <Route
            key={item.name}
            exact={item.exact}
            element={element}
            path={path}
          />
        );
      })}
    </Routes>
  );
});
