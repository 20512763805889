import clsx from "clsx";
import React, { useRef, useState } from "react";
import search from "assets/icons/search.svg";
import IconSvg from "components/IconSvg";
import iconCancel20 from "assets/icons/cancel20.svg";
import {
  getItemStorage,
  removeSearchKeyword,
  saveSearchKeyword,
  SEARCH_KEY,
} from "utils/storage";
import { useClickOutside } from "hooks/useClickOutside";
import { useNavigate } from "react-router-dom";
import { appRoutes } from "../../constants";

export default function InputSearch() {
  const [isExpand, setIsExpand] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [keywords, setKeywords] = useState(getItemStorage(SEARCH_KEY) || []);
  const inputRef = useRef();
  const boxSearchRef = useRef();
  const navigate = useNavigate();

  const handleFocus = () => {
    if (keywords.length > 0) {
      setIsExpand(true);
    }
  };

  const handleBlur = () => {
    setIsExpand(false);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (inputValue) {
        saveSearchKeyword(inputValue);
        setKeywords(getItemStorage(SEARCH_KEY));
        handleBlur();
        navigate(`${appRoutes.search}?q=${inputValue}`);
      }
    }
  };

  const handleRemoveKeyword = (keyword) => {
    removeSearchKeyword(keyword);
    setKeywords(getItemStorage(SEARCH_KEY));
  };

  useClickOutside([inputRef, boxSearchRef], () => {
    handleBlur();
  });

  return (
    <div
      className={clsx(
        "hidden md:flex relative pl-4 py-2 pr-3 items-center h-[40px] bg-[#FFECF4] flex-1 max-w-[400px] lg:max-w-[450px] 2xl:max-w-[500px]",
        {
          "rounded-tl-[20px] rounded-tr-[20px]": isExpand,
          "rounded-[20px]": !isExpand,
        }
      )}
    >
      <img src={search} alt="" className="flex-shrink-0 cursor-pointer" />
      <input
        placeholder="Tìm kiếm bài hát, nghệ sĩ ..."
        type="text"
        className="flex-1 border-none outline-none px-1 bg-transparent placeholder:text-sm placeholder:text-232323 placeholder:text-opacity-40"
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        value={inputValue}
        onChange={handleChange}
        ref={inputRef}
      />
      <div
        ref={boxSearchRef}
        className={clsx(
          "absolute top-full border-t border-t-[#8C8C8C] border-opacity-80 left-0 bg-[#FFECF4] w-full rounded-bl-[20px] rounded-br-[20px] overflow-hidden",
          {
            block: isExpand,
            hidden: !isExpand,
          }
        )}
      >
        <div className="p-4 bg-[#ffecf4] flex-col justify-start items-center gap-6 flex">
          <div className="self-stretch flex-col justify-start items-start gap-3 flex">
            <div className="text-[#232323] text-xs font-semibold leading-[18px]">
              Các tìm kiếm gần đây
            </div>
            <div className="self-stretch flex-col justify-start items-start gap-2 flex">
              {keywords
                .slice(-5)
                .reverse()
                .map((word) => {
                  return (
                    <div
                      key={word}
                      className="self-stretch py-2 justify-start items-center gap-6 inline-flex"
                      onClick={(e) => {
                        console.log("clicked...");
                        handleBlur();
                        setInputValue(word);
                        navigate(`${appRoutes.search}?q=${word}`);
                      }}
                    >
                      <div className="w-6 h-6 relative">
                        <IconSvg
                          icon={search}
                          className="w-[24px] h-[24px] cursor-pointer"
                          color="#8C8C8C"
                        />
                      </div>
                      <div className="grow shrink basis-0 h-[22px] justify-start items-start gap-1 flex">
                        <div className="opacity-50 text-[#232323] text-sm font-normal leading-snug">
                          {word}
                        </div>
                      </div>
                      <div className="w-5 h-5 relative">
                        <IconSvg
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveKeyword(word);
                          }}
                          icon={iconCancel20}
                          className="w-[24px] h-[24px] cursor-pointer"
                          color="#8C8C8C"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
