import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  FacebookAuthProvider,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyActQYa_E3bjFNP4e5ftmlEC_fC3h9ubk8",
  authDomain: "starclub-c7827.firebaseapp.com",
  projectId: "starclub-c7827",
  storageBucket: "starclub-c7827.firebasestorage.app",
  messagingSenderId: "297291758233",
  appId: "1:297291758233:web:f72bb4875ae486f2875608",
  measurementId: "G-QQ7EL453YT"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const googleProvider = new GoogleAuthProvider();
export const facebookProvider = new FacebookAuthProvider();
// Initialize Firebase Authentication and get a reference to the service
export const myAuth = getAuth(app);
export default app;
