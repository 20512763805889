import bell from "assets/icons/bell.svg";
import download from "assets/icons/download-app.svg";
import hambuger from "assets/icons/hambuger.svg";
import ForgetPasswordModal from "components/Modal/ForgetPassword";
import LoginModal from "components/Modal/Login";
import RegisterModal from "components/Modal/Register";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenuLeft } from "../../redux/slice/menuLeftSlice";
import AccountMenu from "./AccountMenu";
import InputSearch from "./InputSearch";
import "./styles.scss";

function MenuHeader() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isForgetOpen, setIsForgetOpen] = useState(false);
  return (
    <div
      className={
        "z-[100] px-6 md:px-12 select-none bg-white flex justify-between gap-3 items-center py-5 sticky top-0"
      }
    >
      <div className="flex gap-3 flex-1">
        <div className="flex gap-3 md:hidden">
          <img
            className="cursor-pointer"
            src={hambuger}
            alt=""
            onClick={() => dispatch(toggleMenuLeft())}
          />
          <p className="font-racing-sans-one text-[26px]">Star*Club</p>
        </div>
        <InputSearch />
      </div>
      {userInfo ? (
        <div className="flex items-center gap-3">
          {userInfo?.premiums?.[0]?.is_pro === 0 && (
            <button className="hidden lg:flex flex-shrink-0 hover:shadow-sm duration-500 items-center border-none h-10 outline-none bg-FF418C text-white font-medium text-sm rounded-[100px] px-3">
              Nâng cấp tài khoản
            </button>
          )}
          <a
            href="http://starclub.vn/about/download/"
            target="_blank"
            rel="noreferrer"
            className="hidden lg:block"
          >
            <div className="h-10 pl-4 pr-6 bg-[#ffecf4] hover:shadow-sm duration-500 rounded-3xl justify-start items-center gap-2 inline-flex">
              <div className="w-6 h-6 relative">
                <img src={download} alt="" />
              </div>
              <div className="text-[#ff418c] text-sm font-normal leading-snug">
                Tải ứng dụng
              </div>
            </div>
          </a>

          <div className="justify-start items-center gap-3 flex">
            <div className="w-10 h-10 px-2.5 bg-[#eeeeee] rounded-3xl justify-center items-center flex cursor-pointer relative">
              <div className="">
                <img src={bell} alt="" />
                <div className="absolute -right-[2px] -bottom-[2px] h-4 w-4 px-1 bg-[#591731] rounded-[100px] justify-center items-center inline-flex">
                  <div className="text-white text-[10px] font-normal leading-none">
                    3
                  </div>
                </div>
              </div>
            </div>
            <AccountMenu userInfo={userInfo} />
          </div>
        </div>
      ) : (
        <div className="h-10 justify-start items-center gap-4 flex">
          <button
            onClick={() => setIsLoginOpen(true)}
            className="h-10 px-6 bg-[#ffecf4] rounded-3xl justify-start items-center gap-2 flex cursor-pointer"
          >
            <div className="text-[#232323] text-sm font-normal leading-snug">
              Đăng nhập
            </div>
          </button>
          <button
            onClick={() => setIsRegisterOpen(true)}
            className="hidden sm:flex h-10 px-6 bg-[#ff418c] rounded-3xl justify-start items-center gap-2 cursor-pointer"
          >
            <div className="text-white text-sm font-normal leading-snug">
              Đăng ký
            </div>
          </button>
        </div>
      )}
      {isLoginOpen && (
        <LoginModal
          open={isLoginOpen}
          handleClose={() => setIsLoginOpen(false)}
          setIsRegisterOpen={setIsRegisterOpen}
          setIsForgetOpen={setIsForgetOpen}
        />
      )}
      {isRegisterOpen && (
        <RegisterModal
          open={isRegisterOpen}
          handleClose={() => setIsRegisterOpen(false)}
          setIsLoginOpen={setIsLoginOpen}
        />
      )}
      {isForgetOpen && (
        <ForgetPasswordModal
          open={isForgetOpen}
          handleClose={() => setIsForgetOpen(false)}
          setIsForgetOpen={setIsForgetOpen}
          setIsLoginOpen={setIsLoginOpen}
        />
      )}
    </div>
  );
}

export default MenuHeader;
