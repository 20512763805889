import { useSelector } from "react-redux";
import CommentItem from "./CommentItem";
import PerfectScrollbar from "react-perfect-scrollbar";

export default function CommentTree({ comments, handleLike, sendComment }) {
  const { userInfo } = useSelector((state) => state.auth);
  
  return (
    <PerfectScrollbar className="w-full overflow-y-auto pr-1 overflow-x-hidden grow shrink basis-0 pb-2 flex-col justify-start items-start gap-3 flex">
      {comments?.map((comment) => (
        <CommentItem
          key={`comment-parent-${comment?.id}`}
          userInfo={userInfo}
          handleLike={handleLike}
          comment={comment}
          sendComment={sendComment}
          level={1}
          idLevel1={comment?.id}
          isLiked={comment?.userLike?.status}
        />
      ))}
    </PerfectScrollbar>
  );
}
