import iconGift from "assets/icons/gift-star.svg";
import iconHeart18 from "assets/icons/heart18.svg";
import clsx from "clsx";
import IconSvg from "components/IconSvg";
import MenuExtendSong from "components/MenuExtendSong";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useRef } from "react";
import { BsMusicNoteBeamed } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { checkResponse } from "utils";
import { openModalDownloadApp } from "../../redux/slice/commonSlice";
import {
  handleCanPlayAudio,
  handleErrorLoadAudio,
  handleLoadedAudio,
  setDuration,
  setLoadingAudio,
  setStatePlayer,
  setTimeProgress,
} from "../../redux/slice/playerSlice";
import { addFavoriteAudio } from "../../services";
import { TYPE_SHARE_LINK } from "../../constants";

const DisplayTrack = ({
  audioRef,
  progressBarRef,
  onNextAudio,
  infoAudio,
  loop,
  isLiked,
  setIsLiked,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const loadingRef = useRef(false);

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    dispatch(setDuration(seconds));
    progressBarRef.current.max = seconds;
  };
  const wrapTitleRef = useRef();
  const titleRef = useRef();
  const isRunningTitle = useRef(false);

  const handleError = () => {
    enqueueSnackbar("Lỗi tải bài hát", {
      variant: "error",
    });
    dispatch(handleErrorLoadAudio());
  };

  useEffect(() => {
    const text = titleRef.current;
    text.classList.remove("text-scroll-to-left");
    isRunningTitle.current = false;
  }, [infoAudio]);

  const handleHoverTitle = () => {
    if (isRunningTitle.current) return;
    const container = wrapTitleRef.current;
    const text = titleRef.current;
    const containerWidth = container.offsetWidth;
    const textWidth = text.offsetWidth;

    // Nếu văn bản dài hơn container, thì bắt đầu chạy animation
    if (textWidth > containerWidth) {
      text.style.animationDuration = textWidth / 50 + "s";
      isRunningTitle.current = true;
      text.classList.add("text-scroll-to-left");
      setTimeout(() => {
        isRunningTitle.current = false;
        text.classList.remove("text-scroll-to-left");
      }, (textWidth / 50) * 1000);
    } else {
      text.classList.remove("text-scroll-to-left");
    }
  };

  const handleCanPlay = () => {
    dispatch(handleCanPlayAudio());
  };

  const handleWaiting = () => {
    dispatch(setLoadingAudio(true));
  };

  const handleLoadStart = () => {
    dispatch(
      setStatePlayer({
        loading: true,
        open: true,
      })
    );
  };

  const handleLoadedData = () => {
    dispatch(handleLoadedAudio());
  };

  const handleTimeUpdate = () => {
    dispatch(setTimeProgress(audioRef.current.currentTime));
  };

  const handleLike = useCallback(() => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    addFavoriteAudio({
      id: infoAudio?.id,
    })
      .then((res) => {
        if (checkResponse(res)) {
          setIsLiked(!!res.data.data.status);
        }
      })
      .finally(() => {
        loadingRef.current = false;
      });
  }, [infoAudio?.id, setIsLiked]);

  const onEnded = () => {
    if (loop === 2) {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    } else {
      onNextAudio();
    }
  };

  const openDownloadApp = () => {
    dispatch(openModalDownloadApp());
  };

  return (
    <div>
      <audio
        id="audio-track"
        src={infoAudio?.audio_url}
        ref={audioRef}
        onLoadedMetadata={onLoadedMetadata}
        onEnded={onEnded}
        onError={handleError}
        onLoadedData={handleLoadedData}
        onCanPlay={handleCanPlay}
        onWaiting={handleWaiting}
        onLoadStart={handleLoadStart}
        onTimeUpdate={handleTimeUpdate}
      />
      <div className="flex gap-[10px] sm:gap-[20px] items-center">
        <div className="w-[64px] h-[64px] rounded-[8px] overflow-hidden bg-1e1e1e">
          {infoAudio?.thumb ? (
            <img
              src={infoAudio?.thumb}
              alt="audio avatar"
              className="w-full h-full object-cover align-middle"
            />
          ) : (
            <div className="flex justify-center items-center h-full">
              <span className="text-[30px] flex justify-center items-center h-[60px] w-[60px] rounded-full bg-[#989898]">
                <BsMusicNoteBeamed />
              </span>
            </div>
          )}
        </div>
        <div
          className="w-[150px] sm:w-[174px] flex flex-col justify-center"
          onMouseEnter={handleHoverTitle}
          ref={wrapTitleRef}
        >
          <div className="is-mark-title-song">
            <div className="flex whitespace-nowrap">
              <p
                className="text-[14px]  font-medium leading-20px text-ellipsis whitespace-nowrap"
                ref={titleRef}
              >
                {infoAudio?.title}
              </p>
            </div>
          </div>

          <p className=" text-opacity-50 text-[12px]">
            {infoAudio?.artist?.name}
          </p>
        </div>
        <div className="hidden sm:flex h-14 justify-start items-center gap-2">
          <button
            onClick={openDownloadApp}
            className="w-10 h-10 px-2.5 bg-[#eeeeee] rounded-[99px] justify-center items-center flex cursor-pointer"
          >
            <div className="w-[18px] h-[18px] relative">
              <img src={iconGift} alt="" />
            </div>
          </button>
          <button
            onClick={handleLike}
            className="w-10 h-10 px-2.5 justify-center items-center flex cursor-pointer hover:bg-[#eeeeee] hover:rounded-[99px]"
          >
            <IconSvg
              icon={iconHeart18}
              className={clsx("w-[18px] h-[18px] cursor-inherit", {
                like: isLiked,
                unlike: !isLiked,
              })}
              color={isLiked ? "#FF418C" : "#545454"}
            />
          </button>
          <MenuExtendSong
            infoAudio={infoAudio}
            isAudio
            typeShare={TYPE_SHARE_LINK.audio}
          />
        </div>
      </div>
    </div>
  );
};

export default DisplayTrack;
