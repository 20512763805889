import clsx from "clsx";
import useModalAuth from "hooks/useModalAuth";
import { useCallback, useMemo } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { TYPE_PLAY_IN_LIST } from "../constants";
import EqualizerGif from "./EqualizerGif";

const PlayItem = ({
  isActive,
  isPlaying,
  songTitle,
  singerName,
  thumbnail,
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      className={clsx(
        "self-stretch h-[59px] p-1.5 rounded-md flex-col justify-start items-center gap-2.5 flex cursor-pointer transition-all duration-100",
        {
          "hover:bg-[#cacaca]/50": !isActive,
          "bg-[#ff418c]": isActive,
        }
      )}
    >
      <div className="self-stretch rounded-lg justify-start items-center gap-6 flex">
        <div className="grow shrink basis-0 h-[47px] justify-start items-center gap-2 flex">
          <div className="grow shrink basis-0 h-[47px] justify-start items-center gap-4 flex">
            <img
              className="w-[47px] h-[47px] object-cover rounded"
              src={thumbnail}
              alt=""
            />
            <div className="grow shrink basis-0 flex-col justify-start items-start flex">
              <div className="self-stretch justify-start items-center gap-1 flex">
                <div
                  className={clsx(
                    "text-sm font-semibold leading-tight line-clamp-1",
                    {
                      "text-[#232323]": !isActive,
                      "text-white": isActive,
                    }
                  )}
                >
                  {songTitle}
                </div>
                {isPlaying ? (
                  <EqualizerGif isPlaying={isPlaying} isWhiteColor />
                ) : null}
              </div>
              <div className="self-stretch justify-start items-center gap-1 flex">
                <div
                  className={clsx(
                    "grow shrink basis-0 line-clamp-1 text-xs font-normal leading-[18px]",
                    {
                      "text-[#767676]": !isActive,
                      "text-[#ffc4db]": isActive,
                    }
                  )}
                >
                  {singerName}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function PlayList() {
  const { openPlayList } = useSelector((state) => state.player);
  const {
    infoAudio,
    isPlaying,
    playListRecommend,
    playListInAlbum,
    infoAlbum,
    isShowRecommend,
    playingIn,
  } = useSelector((state) => state.player);

  const { setASong, setASongInAlbum, setASongInRecommend } = useModalAuth();

  const setSong = useCallback(
    (itemSong) => {
      if (playingIn === TYPE_PLAY_IN_LIST.album) {
        setASongInAlbum(itemSong);
      }
      if (playingIn === TYPE_PLAY_IN_LIST.recommend) {
        setASongInRecommend(itemSong);
      }
      if (playingIn === TYPE_PLAY_IN_LIST.song) {
        setASong(itemSong);
      }
    },
    [playingIn, setASong, setASongInAlbum, setASongInRecommend]
  );

  const listAudioPrev = useMemo(() => {
    if (playingIn === TYPE_PLAY_IN_LIST.album) {
      const indexSongInAlbum = playListInAlbum.findIndex(
        (i) => i.id === infoAudio?.id
      );
      if (indexSongInAlbum > -1) {
        return playListInAlbum.slice(0, indexSongInAlbum + 1);
      } else {
        return [];
      }
    } else if (playingIn === TYPE_PLAY_IN_LIST.recommend) {
      const indexSongInRecommend = playListRecommend.findIndex(
        (i) => i.id === infoAudio?.id
      );
      if (indexSongInRecommend > -1) {
        return playListRecommend.slice(0, indexSongInRecommend + 1);
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [infoAudio?.id, playListInAlbum, playListRecommend, playingIn]);

  const listAudioNext = useMemo(() => {
    if (playingIn === TYPE_PLAY_IN_LIST.album) {
      const indexSongInAlbum = playListInAlbum.findIndex(
        (i) => i.id === infoAudio?.id
      );
      if (indexSongInAlbum > -1) {
        return playListInAlbum.slice(
          indexSongInAlbum + 1,
          playListInAlbum?.length
        );
      } else {
        return [];
      }
    } else if (playingIn === TYPE_PLAY_IN_LIST.recommend) {
      const indexSongInRecommend = playListRecommend.findIndex(
        (i) => i.id === infoAudio?.id
      );
      if (indexSongInRecommend > -1) {
        return playListRecommend.slice(
          indexSongInRecommend + 1,
          playListRecommend?.length
        );
      } else {
        return [];
      }
    } else {
      return [];
    }
  }, [infoAudio?.id, playListInAlbum, playListRecommend, playingIn]);

  return (
    <div
      className={clsx(
        "h-[calc(100%-90px)] border-b border-b-[#CACACA] 2xl:h-full bg-white z-[100] absolute right-0 top-0 2xl:relative"
      )}
    >
      <PerfectScrollbar
        className={clsx("transition-all duration-300", {
          "basis-[368px] w-[368px] flex-shrink-0 h-full overflow-y-auto":
            openPlayList,
          "basis-[0px] w-0 overflow-hidden": !openPlayList,
        })}
      >
        <div className="h-full p-6 w-full bg-white border-l border-[#eeeeee] flex-col justify-start items-start gap-4 flex">
          <div className="w-full flex-col justify-start items-start gap-4 flex">
            <div className="w-full flex-col justify-start items-start gap-4 flex">
              <div className="w-full flex-col justify-start items-start gap-2 flex">
                <div className="self-stretch pl-1.5 justify-start items-center gap-[81px] flex">
                  <div className="flex-col justify-start items-start flex">
                    <div className="text-[#232323] text-sm font-semibold  leading-tight">
                      Danh sách phát
                    </div>
                    <div className="justify-start items-start gap-1 flex">
                      {infoAlbum?.title &&
                      playingIn === TYPE_PLAY_IN_LIST.album ? (
                        <div className="text-[#767676] text-xs font-normal  leading-[18px]">
                          Từ Album: {infoAlbum?.title}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                  {playingIn === TYPE_PLAY_IN_LIST.song && infoAudio ? (
                    <PlayItem
                      isActive={true}
                      isPlaying={isPlaying}
                      thumbnail={infoAudio?.thumb}
                      singerName={infoAudio?.artist?.name}
                      songTitle={infoAudio?.title}
                    />
                  ) : null}

                  {listAudioPrev.length
                    ? listAudioPrev?.map((item) => (
                        <PlayItem
                          key={`song-play-list-${item?.id}`}
                          onClick={() => {
                            setSong(item);
                          }}
                          isPlaying={
                            isPlaying &&
                            infoAudio &&
                            item &&
                            infoAudio?.id === item?.id
                          }
                          isActive={
                            infoAudio && item && infoAudio?.id === item?.id
                          }
                          thumbnail={item?.thumb}
                          singerName={item?.artist?.name}
                          songTitle={item?.title}
                        />
                      ))
                    : null}
                </div>
              </div>
              <div className="w-full flex-col justify-start items-start gap-2 flex">
                {listAudioNext.length ? (
                  <div className="self-stretch pl-1.5 justify-start items-center gap-[81px] flex">
                    <div className="flex-col justify-start items-start flex">
                      <div className="w-[86px] text-[#232323] text-sm font-semibold  leading-tight">
                        Bài tiếp theo
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                  {listAudioNext.length
                    ? listAudioNext?.map((item) => (
                        <PlayItem
                          key={`song-play-list-${item?.id}`}
                          onClick={() => {
                            setSong(item);
                          }}
                          isPlaying={
                            isPlaying &&
                            infoAudio &&
                            item &&
                            infoAudio?.id === item?.id
                          }
                          isActive={
                            infoAudio && item && infoAudio?.id === item?.id
                          }
                          thumbnail={item?.thumb}
                          singerName={item?.artist?.name}
                          songTitle={item?.title}
                        />
                      ))
                    : null}
                </div>
              </div>

              {isShowRecommend && playingIn !== TYPE_PLAY_IN_LIST.recommend && (
                <div className="w-full flex-col justify-start items-start gap-2 flex">
                  <div className="self-stretch pl-1.5 justify-start items-center flex">
                    <div className="flex-col justify-start items-start flex">
                      <div className="text-[#232323] text-sm font-semibold  leading-tight">
                        Tự động phát
                      </div>
                      <div className="justify-start items-start gap-1 flex">
                        <div className="text-[#767676] text-xs font-normal  leading-[18px]">
                          Danh sách bài hát gợi ý
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                    {playListRecommend?.map((item) => (
                      <PlayItem
                        key={`song-play-recommend-${item?.id}`}
                        onClick={() => {
                          setASongInRecommend(item);
                        }}
                        isPlaying={
                          isPlaying &&
                          infoAudio &&
                          item &&
                          infoAudio?.id === item?.id
                        }
                        isActive={
                          infoAudio && item && infoAudio?.id === item?.id
                        }
                        thumbnail={item?.thumb}
                        singerName={item?.artist?.name}
                        songTitle={item?.title}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}
