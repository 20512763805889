import { createSlice } from "@reduxjs/toolkit";

const menuLeftSlice = createSlice({
  name: "menuLeftSlice",
  initialState: {
    open: true,
    menuActive: "",
  },
  reducers: {
    openMenuLeft: (state) => {
      state.open = true;
    },
    closeMenuLeft: (state) => {
      state.open = false;
    },
    toggleMenuLeft: (state) => {
      state.open = !state.open;
    },
    setMenuActive(state, action) {
      state.menuActive = action.payload;
    },
  },
});

export const { openMenuLeft, closeMenuLeft, toggleMenuLeft, setMenuActive } =
  menuLeftSlice.actions;
export default menuLeftSlice.reducer;
