import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TYPE_PLAY_IN_LIST } from "../../constants";
import { getAlbumsDetail, getAudiosRecommend } from "services";
import { checkResponse } from "utils";

function isLastElement(array, index) {
  return index === array.length - 1;
}
function isFirstElement(index) {
  return index === 0;
}

const shuffle = (array, currentItem) => {
  if (currentItem) {
    const newArr = array.filter((i) => i.id !== currentItem.id);
    const current = array.find((i) => i.id === currentItem.id);
    for (let i = newArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[j]] = [newArr[j], newArr[i]];
    }
    return [current, ...newArr];
  } else {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
};

// Define the async thunk
export const fetchAudiosRecommend = createAsyncThunk(
  "playList/fetchAudiosRecommend",
  async (body) => {
    const response = await getAudiosRecommend(body);
    if (checkResponse(response)) {
      return response?.data.data.rows;
    } else {
      throw new Error("Failed to fetchAudiosRecommend");
    }
  }
);
export const fetchAlbumsDetail = createAsyncThunk(
  "playList/fetchAlbumsDetail",
  async (body) => {
    const response = await getAlbumsDetail(body);
    if (checkResponse(response)) {
      return response?.data.data;
    } else {
      throw new Error("Failed to fetchAlbumsDetail");
    }
  }
);
const initialState = {
  open: false, // hiển thị thanh player bottom?
  openComment: false, // show drawer comment bên phải
  typeComment: null, //1:video, 2:audio : đang comment sound hay video
  infoVideo: null,
  openPlayList: false, //mở drawer playlist hay ko?
  infoAudio: null,
  volume: 10, // âm lượng thanh control bottom
  timeProgress: 0,
  duration: 0,
  isPlaying: false,
  loading: false, // trạng thái load audio
  error: false, // load lỗi hay ko
  openLyric: false, // mở dialog lyric
  loop: 0, // 0: off, 1: on, 2: one
  shuffle: false,

  infoAlbum: null, // lưu thông tin album đang vào
  rawPlayListInAlbum: [],
  playListInAlbum: [], // danh sách playlist
  rawPlayListRecommend: [],
  playListRecommend: [], // danh sách gợi ý
  isShowRecommend: true,

  idAlbum: null, // đang play sound tại id album nào
  playingIn: "", //album | recommend | song : vị trí danh sách đang play
  playbackRate: 1,
};

const playerSlice = createSlice({
  name: "playerSlice",
  initialState: initialState,
  reducers: {
    openPlayer: (state) => {
      state.open = true;
    },
    closePlayer: (state) => {
      state.open = false;
    },
    togglePlayer: (state) => {
      state.open = !state.open;
    },
    toggleShuffle: (state) => {
      state.shuffle = !state.shuffle;
    },
    toggleLoop: (state) => {
      switch (
        state.loop // 0: off, 1: on, 2: one
      ) {
        case 0:
        case 1:
          if (state.loop === 0) {
            state.loop = 1;
          } else if (state.loop === 1) {
            state.loop = 2;
          }
          if (state.playingIn === TYPE_PLAY_IN_LIST.album) {
            state.isShowRecommend = false;
          }
          if (state.playingIn === TYPE_PLAY_IN_LIST.recommend) {
            state.isShowRecommend = true;
          }
          if (state.playingIn === TYPE_PLAY_IN_LIST.song) {
            state.isShowRecommend = false;
          }
          break;
        case 2:
          state.loop = 0;
          state.isShowRecommend = true;
          break;
        default:
          state.loop = 0;
          state.isShowRecommend = true;
          break;
      }
    },
    setSingleSong: (state, action) => {
      state.playingIn = TYPE_PLAY_IN_LIST.song;
      state.infoAudio = action.payload;
      state.playListInAlbum = [];
      state.isShowRecommend = true;
    },
    setSongInAlbum: (state, action) => {
      state.playingIn = TYPE_PLAY_IN_LIST.album;
      state.infoAudio = action.payload;
      state.isShowRecommend = false;
    },
    setSongInRecommend: (state, action) => {
      state.playingIn = TYPE_PLAY_IN_LIST.recommend;
      state.infoAudio = action.payload;
      state.isShowRecommend = true;
    },
    setInfoVideo: (state, action) => {
      state.infoVideo = action.payload;
    },
    setPlaybackRate: (state, action) => {
      state.playbackRate = action.payload;
    },
    setAlbumSong: (state, action) => {
      state.playingIn = TYPE_PLAY_IN_LIST.album;
      state.rawPlayListInAlbum = action.payload;
      state.playListInAlbum = action.payload;
    },
    shuffleSongs: (state) => {
      state.shuffle = !state.shuffle;
      if (state.playingIn === TYPE_PLAY_IN_LIST.album) {
        if (state.shuffle === true) {
          const playListInAlbumCopy = JSON.parse(
            JSON.stringify(state.playListInAlbum)
          );
          const shuffledArray = shuffle(playListInAlbumCopy, state.infoAudio);
          state.playListInAlbum = shuffledArray;
        } else {
          state.playListInAlbum = state.rawPlayListInAlbum;
        }
      }
      if (state.playingIn === TYPE_PLAY_IN_LIST.recommend) {
        if (state.shuffle === true) {
          const playListRecommendCopy = JSON.parse(
            JSON.stringify(state.playListRecommend)
          );
          const shuffledArray = shuffle(playListRecommendCopy, state.infoAudio);
          state.playListRecommend = shuffledArray;
        } else {
          state.playListRecommend = state.rawPlayListRecommend;
        }
      }
    },
    handlePrevSong: (state) => {
      if (state.playingIn === TYPE_PLAY_IN_LIST.album) {
        const indexSongInAlbum = state.playListInAlbum.findIndex(
          (i) => i.id === state.infoAudio?.id
        );
        if (!isFirstElement(indexSongInAlbum)) {
          state.infoAudio = state.playListInAlbum[indexSongInAlbum - 1];
        }
      }
      if (state.playingIn === TYPE_PLAY_IN_LIST.recommend) {
        const indexSongInRecommend = state.playListRecommend.findIndex(
          (i) => i.id === state.infoAudio?.id
        );
        if (!isFirstElement(indexSongInRecommend)) {
          state.infoAudio = state.playListRecommend[indexSongInRecommend - 1];
        }
      }
      if (state.playingIn === TYPE_PLAY_IN_LIST.song) {
      }
    },
    handleNextSong: (state) => {
      if (state.playingIn === TYPE_PLAY_IN_LIST.album) {
        const indexSongInAlbum = state.playListInAlbum.findIndex(
          (i) => i.id === state.infoAudio?.id
        );
        if (isLastElement(state.playListInAlbum, indexSongInAlbum)) {
          if (state.loop) {
            state.infoAudio = state.playListInAlbum[0];
            state.playingIn = TYPE_PLAY_IN_LIST.album;
          }
        } else {
          state.infoAudio = state.playListInAlbum[indexSongInAlbum + 1];
          state.playingIn = TYPE_PLAY_IN_LIST.album;
        }
      }
      if (state.playingIn === TYPE_PLAY_IN_LIST.recommend) {
        const indexSongInRecommend = state.playListRecommend.findIndex(
          (i) => i.id === state.infoAudio?.id
        );
        if (!isLastElement(state.playListRecommend, indexSongInRecommend)) {
          state.infoAudio = state.playListRecommend[indexSongInRecommend + 1];
        }
      }
      if (state.playingIn === TYPE_PLAY_IN_LIST.song) {
        if (state.isShowRecommend) {
          state.infoAudio = state.playListRecommend[0];
          state.playingIn = TYPE_PLAY_IN_LIST.recommend;
        }
      }
    },
    hideAllPlayer: (state) => {
      state.open = false;
      state.openLyric = false;
      state.openPlayList = false;
      state.openComment = false;
      state.isPlaying = false;
    },
    openLyric: (state) => {
      state.openLyric = true;
    },
    closeLyric: (state) => {
      state.openLyric = false;
    },
    toggleLyric: (state) => {
      state.openLyric = !state.openLyric;
    },
    toggleComment: (state, action) => {
      if (state.openPlayList) {
        state.openPlayList = false;
      }
      if (state.openLyric) {
        state.openLyric = false;
      }
      state.openComment = !state.openComment;
      state.typeComment = action.payload;
    },
    togglePlayList: (state) => {
      state.openPlayList = !state.openPlayList;
    },
    setInfoAudio: (state, action) => {
      state.infoAudio = action.payload;
    },
    togglePlayAudio(state) {
      state.isPlaying = !state.isPlaying;
    },
    playAudio(state) {
      if (state.error) {
        state.error = false;
      }
      state.isPlaying = true;
    },
    pauseAudio(state) {
      state.isPlaying = false;
    },
    setVolume(state, action) {
      state.volume = action.payload;
    },
    setTimeProgress: (state, action) => {
      state.timeProgress = action.payload;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
    handleErrorLoadAudio(state) {
      state.error = true;
      state.isPlaying = false;
      state.infoAudio = null;
      state.open = false;
      state.openLyric = false;
    },
    handleLoadedAudio(state) {
      state.error = false;
      state.isPlaying = true;
      state.open = true;
    },
    handleCanPlayAudio(state) {
      state.loading = false;
      state.error = false;
    },
    setLoadingAudio(state, action) {
      state.loading = action.payload;
    },
    setStatePlayer(state, action) {
      for (const key in action.payload) {
        if (Object.prototype.hasOwnProperty.call(action.payload, key)) {
          const element = action.payload[key];
          state[`${key}`] = element;
        }
      }
    },
    resetPlayer: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAudiosRecommend.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAudiosRecommend.fulfilled, (state, action) => {
        state.loading = false;
        state.playListRecommend = action.payload;
        state.rawPlayListRecommend = action.payload;
      })
      .addCase(fetchAudiosRecommend.rejected, (state, action) => {
        state.loading = false;
        // state.error = action.error.message;
      })
      .addCase(fetchAlbumsDetail.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAlbumsDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.rawPlayListInAlbum = action.payload?.audios;
        state.playListInAlbum = action.payload?.audios;
        state.infoAlbum = action.payload;
        state.playingIn = TYPE_PLAY_IN_LIST.album;
        // if (action.payload?.audios?.[0]) {
        //   state.infoAudio = action.payload?.audios?.[0];
        // }
      })
      .addCase(fetchAlbumsDetail.rejected, (state, action) => {
        state.loading = false;
        state.rawPlayListInAlbum = [];
        state.playListInAlbum = [];
        state.infoAlbum = null;
        state.playingIn = TYPE_PLAY_IN_LIST.album;
        // state.error = action.error.message;
      });
  },
});

export const {
  openPlayer,
  closePlayer,
  togglePlayer,
  toggleLoop,
  toggleShuffle,
  openLyric,
  closeLyric,
  toggleLyric,
  toggleComment,
  togglePlayList,
  setTimeProgress,
  setDuration,
  togglePlayAudio,
  setInfoAudio,
  hideAllPlayer,
  playAudio,
  pauseAudio,
  setVolume,
  handleErrorLoadAudio,
  handleLoadedAudio,
  handleCanPlayAudio,
  setLoadingAudio,
  handleNextSong,
  handlePrevSong,
  setSingleSong,
  setSongInAlbum,
  setSongInRecommend,
  setAlbumSong,
  shuffleSongs,
  setInfoVideo,
  resetPlayer,
  setStatePlayer,
  setPlaybackRate,
} = playerSlice.actions;

export default playerSlice.reducer;
