import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

function TextInputField({ placeholder, sx, multiline, rows, name, control }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className="w-full">
            <TextField
              {...field}
              multiline={multiline}
              rows={rows}
              error={!!error?.message}
              placeholder={placeholder}
              type={"text"}
              fullWidth
              sx={{
                height: "50px",
                background: "#eeeeee",
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  height: "50px",
                  "& input": {
                    padding: "10px",
                    color: "#000",
                    "&::placeholder": {
                      color: "#767676",
                      fontSize: "14px",
                    },
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
                ...sx,
              }}
            />
            <ErrorMessage message={error?.message} />
          </div>
        );
      }}
    />
  );
}

export default TextInputField;
