import clsx from "clsx";
import React from "react";

export default function ButtonControl({
  onClick,
  classNameButton,
  classRadius,
  children,
  ...props
}) {
  return (
    <button
      {...props}
      onClick={onClick}
      className={clsx(
        "w-10 h-10 outline-none transition-all flex justify-center items-center",
        classNameButton,
        classRadius ? classRadius : "rounded-full",
        {
          "cursor-not-allowed opacity-30": props.disabled,
        }
      )}
    >
      {children}
    </button>
  );
}
