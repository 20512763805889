import apiClient from "./apiClient";

export const getComments = (body) => {
  return apiClient.post("/comments/list", body);
};
export const likeComments = (body) => {
  return apiClient.post("/comments/like", body);
};
export const deleteComments = (body) => {
  return apiClient.post("/comments/delete", body);
};
export const updateComments = (body) => {
  return apiClient.post("/comments/update", body);
};
export const createComments = (body) => {
  return apiClient.post("/comments/create", body);
};
