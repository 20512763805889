import { useCallback, useEffect } from "react";

export const useClickOutside = (refs, callback) => {
  const handleClick = useCallback(
    (e) => {
      // Kiểm tra nếu click ra ngoài cả hai vùng
      const isOutside = refs.every(
        (ref) => ref.current && !ref.current.contains(e.target)
      );
      if (isOutside) {
        callback();
      }
    },
    [callback, refs]
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick, refs]); // Thêm refs vào dependency array
};
