import { configureStore } from "@reduxjs/toolkit";
import menuLeftSlice from "./slice/menuLeftSlice";
import playListSlice from "./slice/playListSlice";
import playerSlice from "./slice/playerSlice";
import authSlice from "./slice/authSlice";
import commonSlice from "./slice/commonSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    player: playerSlice,
    playList: playListSlice,
    menuLeft: menuLeftSlice,
    common: commonSlice,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export default store;
