import React from "react";

export default function ErrorMessage({ message }) {
  if (!message) {
    return <></>;
  }
  return (
    <p className="text-red-600 text-[13px] mt-1">
      {message}
    </p>
  );
}
