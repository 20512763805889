import { Menu, MenuItem, Popover } from "@mui/material";
import iconplaylist20 from "assets/icons/add-playlist-20.svg";
import iconCaretRight from "assets/icons/caret-right12.svg";
import iconDots from "assets/icons/dots-three-outline-vertical.svg";
import iconLink16 from "assets/icons/link16.svg";
import iconmusic20 from "assets/icons/music20.svg";
import iconPlaylist from "assets/icons/playlist-plus16.svg";
import iconShare16 from "assets/icons/share16.svg";
import useActionPlayer from "hooks/useActionPlayer";
import { useServices } from "hooks/useServices";
import ModalAdd from "pages/PlayList/components/ModalAdd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getPlaylists } from "services";
import { checkResponse, copyToClipboard } from "utils";
import { getShareUrlAudio, getShareUrlVideo } from "utils/player";
import ModalShare from "./ModalShare";
import { TYPE_SHARE_LINK } from "../../constants";
import useModalAuth from "hooks/useModalAuth";
import { useDebounce } from "use-debounce";
import PerfectScrollbar from "react-perfect-scrollbar";
import { addAudioPlayList } from "services/playlist";
import { enqueueSnackbar } from "notistack";

export default function MenuExtendSong({
  infoAudio,
  infoVideo,
  isVideo,
  isAudio,
  isShare = true,
  isCopyLink = true,
  isAddLibrary = true,
  anchorOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  typeShare = TYPE_SHARE_LINK.audio,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { checkAuthToST } = useModalAuth();
  const services = useServices();

  const {
    isOpenModalShare,
    onOpenModalShare,
    onCloseModalShare,
    isOpenModalAddPlaylist,
    onOpenModalAddPlaylist,
    onCloseModalAddPlaylist,
  } = useActionPlayer();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const shareUrl = useMemo(() => {
    if (isAudio) {
      return getShareUrlAudio({ title: infoAudio?.title, id: infoAudio?.id });
    }
    if (isVideo) {
      return getShareUrlVideo({ title: infoVideo?.title, id: infoVideo?.id });
    }
    return "";
  }, [isAudio, isVideo, infoAudio, infoVideo]);

  const handleCopyLink = useCallback(() => {
    checkAuthToST()
      .then(() => {
        copyToClipboard(shareUrl);
        handleClose();
      })
      .catch(() => {});
  }, [checkAuthToST, handleClose, shareUrl]);

  const onCreatePlaylist = () => {
    onOpenModalAddPlaylist();
  };

  const onCreateSuccess = (data) => {
    console.log(data.data);
  };

  const onAddToFavorite = (itemSelected) => {
    console.log(itemSelected);
    services(() =>
      addAudioPlayList({
        audio_id: infoAudio?.id?.toString(),
        playlist_id: itemSelected?.id,
      })
    ).then((res) => {
      if (checkResponse(res)) {
        enqueueSnackbar("Đã thêm vào playlist", {
          variant: "success",
        });
        handleClose();
      }
    });
  };

  const handleShare = useCallback(() => {
    checkAuthToST()
      .then(() => {
        onOpenModalShare();
        handleClose();
      })
      .catch(() => {});
  }, [checkAuthToST, handleClose, onOpenModalShare]);

  return (
    <Fragment>
      <div
        className="w-10 h-10 px-2.5 justify-center items-center flex cursor-pointer hover:bg-[#eeeeee] hover:rounded-[99px]"
        onClick={handleClick}
      >
        <div className="w-[18px] h-[18px] relative">
          <img src={iconDots} alt="" />
        </div>
      </div>
      {Boolean(anchorEl) && (
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          slotProps={{
            paper: {
              sx: {
                borderRadius: "12px",
                boxShadow: "0px 4px 8px 0px #00000029",
              },
            },
          }}
        >
          {isAddLibrary && (
            <MenuItem sx={{ p: 0 }}>
              <AddPlayList
                onAddToFavorite={onAddToFavorite}
                onCreatePlaylist={onCreatePlaylist}
              />
            </MenuItem>
          )}
          {isShare && (
            <MenuItem onClick={handleShare} sx={{ p: 0 }}>
              <div className="h-[46px] p-3 justify-start items-center gap-2.5 inline-flex">
                <div className="w-4 h-4 relative">
                  <img src={iconShare16} alt="" />
                </div>
                <div className="text-[#545454] text-sm font-normal leading-snug">
                  Chia sẻ
                </div>
              </div>
            </MenuItem>
          )}
          {isCopyLink && (
            <MenuItem onClick={handleCopyLink} sx={{ p: 0 }}>
              <div className="h-[46px] p-3 justify-start items-center gap-2.5 inline-flex">
                <div className="w-4 h-4 relative">
                  <img src={iconLink16} alt="" />
                </div>
                <div className="text-[#545454] text-sm font-normal leading-snug">
                  Sao chép link
                </div>
              </div>
            </MenuItem>
          )}
        </Menu>
      )}
      {isOpenModalAddPlaylist && (
        <ModalAdd
          open={isOpenModalAddPlaylist}
          handleClose={onCloseModalAddPlaylist}
          loadData={onCreateSuccess}
        />
      )}
      {isOpenModalShare && (
        <ModalShare
          shareUrl={shareUrl}
          open={isOpenModalShare}
          handleClose={onCloseModalShare}
          typeShare={typeShare}
        />
      )}
    </Fragment>
  );
}

const AddPlayList = ({ onCreatePlaylist, onAddToFavorite }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [valueDebounce] = useDebounce(inputValue, 300);

  const services = useServices();
  const [playlist, setPlaylist] = useState([]);
  const { userInfo } = useSelector((state) => state.auth);
  const { checkAuthToST } = useModalAuth();

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const id = useMemo(() => (open ? "simple-popover" : undefined), [open]);

  const loadData = useCallback(
    (text_search) => {
      services(() =>
        getPlaylists({
          page_id: 0,
          limit: 10,
          text_search: text_search ?? undefined,
        })
      ).then((res) => {
        if (checkResponse(res)) {
          setPlaylist(res.data.data || []);
        }
      });
    },
    [services]
  );

  useEffect(() => {
    if (userInfo) {
      loadData(valueDebounce);
    }
  }, [loadData, anchorEl, userInfo, valueDebounce]);

  const handleClick = useCallback(
    (event) => {
      const currentTarget = event.currentTarget;
      checkAuthToST()
        .then(() => {
          setAnchorEl(currentTarget);
        })
        .catch(() => {});
    },
    [checkAuthToST]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <div>
      <div
        className="h-[46px] p-3 justify-start items-center gap-2.5 inline-flex"
        onClick={handleClick}
      >
        <div className="w-4 h-4 relative">
          <img src={iconPlaylist} alt="" />
        </div>

        <div className="grow shrink basis-0 text-[#545454] text-sm font-normal leading-snug">
          Thêm vào playlist
        </div>
        <div className="w-3 h-3 relative">
          <img src={iconCaretRight} alt="" />
        </div>
      </div>
      <Popover
        id={id}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "12px",
              boxShadow: "0px 4px 8px 0px #00000029",
            },
          },
        }}
      >
        <div>
          <div className="p-3">
            <input
              placeholder="Tìm playlist"
              type="text"
              className="flex-1 pl-3 h-[34px] p-3 rounded-[24px] w-full border-none outline-none bg-[#EEEEEE] placeholder:text-sm placeholder:text-545454 placeholder:text-opacity-40"
              value={inputValue}
              onChange={handleChange}
            />
          </div>

          <MenuItem sx={{ p: 0 }}>
            <div
              onClick={() => {
                onCreatePlaylist && onCreatePlaylist();
                handleClose();
              }}
              className="h-[46px] p-3 justify-start items-center gap-2.5 inline-flex"
            >
              <div className="w-4 h-4 relative rounded bg-[#FF418C]">
                <img src={iconplaylist20} alt="" />
              </div>

              <div className="grow shrink basis-0 text-[#545454] text-sm font-normal leading-snug">
                Tạo mới playlist
              </div>
            </div>
          </MenuItem>
          <PerfectScrollbar className="max-h-[138px] overflow-y-auto">
            {playlist?.map((item) => (
              <MenuItem
                key={`playlist-item-${item.id}`}
                onClick={() => onAddToFavorite(item)}
                sx={{ p: 0 }}
              >
                <div className="h-[46px] p-3 justify-start items-center gap-2.5 inline-flex">
                  <div className="w-4 h-4 relative">
                    <img src={item.thumb ?? iconmusic20} alt="" />
                  </div>
                  <div className="text-[#545454] text-sm font-normal leading-snug">
                    {item.title}
                  </div>
                </div>
              </MenuItem>
            ))}
          </PerfectScrollbar>
        </div>
      </Popover>
    </div>
  );
};
