import clsx from "clsx";
import LyricDialog from "components/LyricDialog/LyricDialog";
import useControlPlayer from "hooks/useControlPlayer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLyric,
  pauseAudio,
  playAudio,
  setPlaybackRate,
  setTimeProgress,
} from "../../redux/slice/playerSlice";
import Controls from "./Controls";
import DisplayTrack from "./DisplayTrack";
import Volume from "./Volume";
import { useServices } from "hooks/useServices";
import { getAudiosDetail } from "services";
import { checkResponse } from "utils";
import { checkIsLiked } from "utils/player";

const PlayerControlContainer = ({ isOpenPlayer }) => {
  // reference
  const audioRef = useRef();
  // const progressBarRef = useRef();
  const {
    infoAudio,
    duration,
    openLyric,
    isPlaying,
    error,
    loading,
    loop,
    shuffle,
    timeProgress,
    playbackRate,
  } = useSelector((state) => state.player);
  const { userInfo } = useSelector((state) => state.auth);
  const [isLiked, setIsLiked] = useState(false);
  const [songDetail, setSongDetail] = useState(null);

  const services = useServices();
  const {
    progressBarRef,
    onResetProgressAudio,
    onSetProgressAudio,
    onPreviousAudio,
    onNextAudio,
    onShuffleAudio,
    isDisableNext,
    isDisablePrev,
  } = useControlPlayer();

  const dispatch = useDispatch();

  useEffect(() => {
    if (infoAudio?.id) {
      services(() =>
        getAudiosDetail({
          id: infoAudio?.id,
        })
      ).then((res) => {
        if (checkResponse(res)) {
          console.log(res.data.data);
          const song = res.data.data;
          setSongDetail(song);
          setIsLiked(checkIsLiked(song?.favorite));
        }
      });
    }
  }, [infoAudio, services]);

  useEffect(() => {
    onResetProgressAudio();
  }, [infoAudio?.audio_url, onResetProgressAudio]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPlaying) {
        dispatch(setTimeProgress(audioRef.current.currentTime));
        onSetProgressAudio(audioRef.current.currentTime, duration);
      }
    }, 1000);

    if (isPlaying) {
      const promise = audioRef.current.play();
      if (promise !== undefined) {
        promise
          .then(() => {
            audioRef.current.play();
            dispatch(playAudio());
          })
          .catch((error) => {
            console.log(error);
            audioRef.current.pause();
            dispatch(pauseAudio());
          });
      }
    } else {
      audioRef.current.pause();
    }

    return () => clearInterval(interval);
  }, [isPlaying, dispatch, duration, onSetProgressAudio]);

  const resetPlaybackRate = useCallback(() => {
    dispatch(setPlaybackRate(1));
    audioRef.current.playbackRate = 1;
  }, [dispatch]);

  useEffect(() => {
    resetPlaybackRate();
  }, [infoAudio?.audio_url, resetPlaybackRate]);

  return (
    <div
      className={clsx(
        "fixed left-0 z-30 w-full border-t border-t-[#CACACA] transition-all duration-200",
        {
          "bottom-0": isOpenPlayer,
          "-bottom-[100vh]": !isOpenPlayer,
        }
      )}
    >
      <div className="bg-white h-[90px] px-2 sm:px-4 flex justify-between gap-2 sm:gap-8 xl:gap-16 items-center w-full">
        <DisplayTrack
          {...{
            infoAudio,
            audioRef,
            progressBarRef,
            onNextAudio,
            loop,
            isLiked,
            setIsLiked,
          }}
        />
        <Controls
          {...{
            audioRef,
            onSetProgressAudio,
            onShuffleAudio,
            progressBarRef,
            onPreviousAudio,
            onNextAudio,
            duration,
            isPlaying,
            error,
            loading,
            timeProgress,
            userInfo,
            loop,
            shuffle,
            infoAudio,
            isDisableNext,
            isDisablePrev,
            playbackRate,
          }}
        />
        <Volume {...{ songDetail, audioRef }} />
      </div>
      <LyricDialog
        {...{
          audioRef,
          duration,
          isPlaying,
          error,
          loading,
          timeProgress,
          songDetail,
          loop,
          shuffle,
          onSetProgressAudio,
          onShuffleAudio,
          progressBarRef,
          onPreviousAudio,
          onNextAudio,
          userInfo,
          isDisableNext,
          isDisablePrev,
        }}
        open={openLyric}
        handleClose={() => dispatch(closeLyric())}
      />
    </div>
  );
};
export default PlayerControlContainer;
