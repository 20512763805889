import { SnackbarProvider } from "notistack";
import ReactDOM from "react-dom/client";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css/scrollbar";
import "swiper/swiper-bundle.css";
import "./styles/customize-progress-bar.scss";
import "./styles/index.scss";
import "react-lazy-load-image-component/src/effects/blur.css";

import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { Router } from "./router";
import App from "./App";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top", // or 'bottom'
          horizontal: "right", // or 'left' or 'center'
        }}
      >
        <App>
          <Router />
        </App>
      </SnackbarProvider>
    </BrowserRouter>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
