import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, FormControlLabel } from "@mui/material";
import clsx from "clsx";
import LoadingButton from "components/Button/LoadingButton";
import ErrorMessage from "components/Form/ErrorMessage";
import PasswordInputField from "components/Form/PasswordInputField";
import TextInputField from "components/Form/TextField";
import { ERROR_MESSAGE } from "constants";
import { useServices } from "hooks/useServices";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  getOTP,
  getUserDetail,
  loginByPassword,
  signUpWithOTP,
} from "services";
import {
  checkResponse,
  formatTime,
  showNotiError,
  showNotiSuccess,
} from "utils";
import { INFO_LOGIN, saveItemStorage } from "utils/storage";
import * as yup from "yup";
import { setUserInfo } from "../../redux/slice/authSlice";
import ModalBase from "./ModalBase";

const STEPS = {
  step1: "1",
  step2: "2",
};
const schema = yup.object().shape({
  step: yup.string(),
  username: yup.string().required(ERROR_MESSAGE.required),
  password: yup.string().required(ERROR_MESSAGE.required),
  accept: yup
    .boolean()
    .oneOf([true], "Vui lòng đồng ý điều khoản và chính sách để tiếp tục")
    .required(ERROR_MESSAGE.required),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Mật khẩu không khớp")
    .required("Bạn phải xác nhận mật khẩu"),
  otp: yup
    .string()
    .nullable()
    .when("step", {
      is: (value) => value === STEPS.step2,
      then: (schema) => schema.required(ERROR_MESSAGE.required),
      otherwise: (schema) => schema,
    }),
});

export default function RegisterModal({ open, handleClose, setIsLoginOpen }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const services = useServices();
  const [seconds, setSeconds] = useState(60);
  const { control, handleSubmit, watch, setValue } = useForm({
    mode: "all",
    defaultValues: {
      step: STEPS.step1,
      username: process.env.REACT_APP_IS_DEV ? "0358290623" : "",
      password: process.env.REACT_APP_IS_DEV ? "123456" : "",
      confirmPassword: process.env.REACT_APP_IS_DEV ? "123456" : "",
      accept: false,
      otp: "",
    },
    resolver: yupResolver(schema),
  });

  const step = watch("step");
  const phone = watch("username");

  const handleSendOTP = useCallback((phone) => {
    return new Promise((resolve) => {
      setLoading(true);
      getOTP({
        username: phone,
        prefix: "signup",
      })
        .then((res) => {
          if (checkResponse(res)) {
            const timeLeft = res.data.data.otp_countdown;
            setSeconds(Number(timeLeft));
            resolve(res);
          } else {
            showNotiError(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    });
  }, []);
  const onSubmit = (data) => {
    setLoading(true);
    if (step === STEPS.step1) {
      handleSendOTP(data.username).then((res) => {
        if (checkResponse(res)) {
          console.log(res);
          setValue("step", STEPS.step2);
        }
      });
    } else if (step === STEPS.step2) {
      console.log(data);
      services(() =>
        signUpWithOTP({
          phone: data.username,
          otp: data.otp,
          password: data.password,
        })
      )
        .then((res) => {
          if (checkResponse(res)) {
            showNotiSuccess("Đăng ký thành công");
            services(() =>
              loginByPassword({
                username: data.username,
                password: data.password,
              })
            )
              .then((resLogin) => {
                if (checkResponse(resLogin)) {
                  const responseLogin = resLogin.data.data;
                  saveItemStorage(INFO_LOGIN, {
                    apiSecret: responseLogin.apiSecret,
                    refreshKey: responseLogin.refreshKey,
                    userId: responseLogin.userId,
                  });
                  services(() => getUserDetail({ id: responseLogin.userId }))
                    .then((resDetail) => {
                      if (checkResponse(resDetail)) {
                        dispatch(setUserInfo(resDetail.data.data));
                      }
                    })
                    .finally(() => {
                      setLoading(false);
                      handleClose();
                    });
                } else {
                  showNotiError(resLogin);
                }
              })
              .finally(() => {
                setLoading(false);
              });
          } else {
            showNotiError(res);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <ModalBase
      title={"Đăng ký tài khoản"}
      open={open}
      handleClose={handleClose}
      className="w-full sm:w-[485px]"
    >
      {step === STEPS.step1 ? (
        <Step1
          loading={loading}
          control={control}
          onSubmit={handleSubmit(onSubmit)}
          setIsLoginOpen={setIsLoginOpen}
          handleClose={handleClose}
        />
      ) : null}
      {step === STEPS.step2 ? (
        <Step2
          loading={loading}
          phone={phone}
          control={control}
          handleSendOTP={handleSendOTP}
          onSubmit={handleSubmit(onSubmit)}
          seconds={seconds}
          setSeconds={setSeconds}
        />
      ) : null}
      <div id="recaptcha-container"></div>
    </ModalBase>
  );
}

const Step1 = ({ loading, control, onSubmit, setIsLoginOpen, handleClose }) => {
  return (
    <div className="self-stretch px-4 py-8 flex-col justify-start items-start gap-8 flex">
      <div className="self-stretch flex-col justify-start items-start gap-8 flex">
        <div className="self-stretch flex-col justify-start items-end gap-4 flex">
          <TextInputField
            placeholder={"Emai hoặc số điện thoại"}
            name="username"
            control={control}
          />
          <PasswordInputField
            placeholder={"Mật khẩu"}
            name="password"
            control={control}
          />
          <PasswordInputField
            placeholder={"Xác nhận mật khẩu"}
            name="confirmPassword"
            control={control}
          />
        </div>
        <div className="self-stretch flex-col justify-start items-start gap-4 flex">
          <Controller
            control={control}
            name={"accept"}
            render={({ field, fieldState: { error } }) => {
              return (
                <div>
                  <FormControlLabel
                    labelPlacement="end"
                    control={
                      <Checkbox
                        {...field}
                        sx={{
                          color: "#ff418c",
                          "&.Mui-checked": {
                            color: "#ff418c",
                          },
                        }}
                      />
                    }
                    label={
                      <div className="grow shrink basis-0">
                        <span className="text-[#232323] text-sm font-normal leading-snug">
                          Tôi đã đọc và đồng ý với{" "}
                        </span>
                        <span className="text-[#232323] text-sm font-semibold leading-snug">
                          Điều khoản sử dụng
                        </span>
                        <span className="text-[#232323] text-sm font-normal leading-snug">
                          {" "}
                          và{" "}
                        </span>
                        <span className="text-[#232323] text-sm font-semibold leading-snug">
                          Chính sách bảo mật
                        </span>
                        <span className="text-[#232323] text-sm font-normal leading-snug">
                          {" "}
                          của Star Club
                        </span>
                      </div>
                    }
                  />
                  <ErrorMessage message={error?.message} />
                </div>
              );
            }}
          />
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={onSubmit}
            className="self-stretch h-[46px] bg-[#ff418c] cursor-pointer select-none rounded-lg flex-col justify-center items-center gap-2 flex"
          >
            Đăng ký
          </LoadingButton>
        </div>
      </div>
      <div className="self-stretch justify-center items-start gap-1 inline-flex">
        <div className="text-[#232323] text-sm font-normal leading-snug">
          Bạn đã có tài khoản?
        </div>
        <div
          onClick={() => {
            setIsLoginOpen(true);
            handleClose();
          }}
          className="text-[#ff418c] text-sm font-semibold leading-snug cursor-pointer"
        >
          Đăng nhập
        </div>
      </div>
    </div>
  );
};

const Step2 = ({
  control,
  onSubmit,
  loading,
  handleSendOTP,
  phone,
  seconds,
  setSeconds,
}) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const intervalId = useRef();

  const startCountdown = useCallback(() => {
    setIsEnabled(false);
    intervalId.current = setInterval(() => {
      let s;
      if (seconds <= 1) {
        clearInterval(intervalId.current);
        setIsEnabled(true);
        s = 0;
      } else {
        s = seconds - 1;
      }
      setSeconds(s);
    }, 1000);
  }, [seconds, setSeconds]);

  useEffect(() => {
    if (seconds > 0) {
      startCountdown();
    }

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [seconds, startCountdown]);

  const handleResendOTP = useCallback(() => {
    if (isEnabled) {
      console.log("OTP sent again!");
      handleSendOTP(phone);
    }
  }, [handleSendOTP, isEnabled, phone]);
  return (
    <div className="self-stretch px-4 py-8 flex-col justify-start items-start gap-8 flex">
      <div className="self-stretch flex-col justify-start items-start gap-8 inline-flex">
        <TextInputField
          placeholder={"Nhập mã OTP"}
          name="otp"
          control={control}
        />
        <div className="self-stretch flex-col justify-start items-start gap-4 flex">
          <div className="w-full items-start gap-4 flex justify-between">
            <button
              disabled={!isEnabled}
              onClick={handleResendOTP}
              className={clsx(
                "flex-shrink-0 select-none text-[#3471e9] text-sm font-medium leading-snug",
                {
                  "cursor-not-allowed text-opacity-50": !isEnabled,
                }
              )}
            >
              Gửi lại
            </button>
            {seconds > 0 && (
              <div className="basis-0 text-right text-[#232323] text-sm font-normal leading-snug">
                {formatTime(seconds)}
              </div>
            )}
          </div>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={onSubmit}
            className="self-stretch h-[46px] bg-[#ff418c] cursor-pointer select-none rounded-lg flex-col justify-center items-center gap-2 flex"
          >
            Đồng ý
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};
