import React from "react";

const GIFPlayer = ({ isPlaying, gifUrl, ...props }) => {
  return (
    <img
      src={gifUrl}
      alt="GIF Animation"
      style={{
        animationPlayState: isPlaying ? "running" : "paused",
      }}
      {...props}
    />
  );
};

export default GIFPlayer;
