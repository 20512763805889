import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";

function PasswordInputField({
  placeholder,
  sx,
  multiline,
  rows,
  name,
  control,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className="w-full">
            <TextField
              {...field}
              multiline={multiline}
              rows={rows}
              error={!!error?.message}
              placeholder={placeholder}
              type={showPassword ? "text" : "password"}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                height: "50px",
                background: "#eeeeee",
                borderRadius: "8px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  height: "50px",
                  "& input": {
                    padding: "10px",
                    color: "#000",
                    "&::placeholder": {
                      color: "#767676",
                      fontSize: "14px",
                    },
                  },
                  "& fieldset": {
                    border: "none",
                  },
                  ...sx,
                },
              }}
            />
            <ErrorMessage message={error?.message} />
          </div>
        );
      }}
    />
  );
}

export default PasswordInputField;
